import React from 'react'
import ReactPhoneNumberInput from 'react-phone-number-input'
// import "react-phone-number-input/style.css" <-- imported in _app.js as there is a bug in next

const PhoneInput = (props) => {
  return (
    <>
      <style>{`
        .PhoneInputInput{
          border: none;
          padding: 4px;
          background: white;
          display: block;
          font-size: 16px;
          width: 100%;
          border-radius:4px;
        }
        .blackPhoneInput input{ color:black !important;}
      `}</style>
      <ReactPhoneNumberInput
        type="tel"
        defaultCountry="GB"
        className="blackPhoneInput"
        style={{ color: 'black !important' }}
        {...props}
      />
    </>
  )
}
export default PhoneInput
