import { Spacer, View, Warning } from '@cnd/elements'
import api from '@services/api'
import PayButton from './PayButton'
import { priceToPounds } from '@cnd/common/functions/menu'
import { updateOrderStatus } from './paymentActions'
import { createAndSendError } from '@services/create-sentry-error'
import { useTypedDispatch } from '@store'
import useOrder, { usePreparedOrder } from '@store/useOrder'
import { useTranslation } from 'react-i18next'
import { STRIPE_ENV } from '@constants/stripe'

const makePaymentForFree = async (order) => {
  try {
    let {
      data: { order: orderWithPayData, payment },
    } = await api.post('/PayForOrderWithNoMoney', { order, env: STRIPE_ENV })

    await updateOrderStatus(orderWithPayData, 'paid')

    return { order: orderWithPayData, payment }
  } catch (e) {
    console.log(e)
    createAndSendError(e)
    try {
      if (e?.response?.data?.error?.error?.message) {
        throw createAndSendError(e.response.data.error.message)
      } else if (e.name === 'AxiosError') {
        createAndSendError(e.data)
        throw {
          error: { ...e?.response?.data?.error, type: 'axios', message: 'There was a problem' },
        }
      } else if (e.error?.reauth) {
        createAndSendError('you need to reauth')
      } else {
        throw e.error
      }
    } catch (error) {
      throw createAndSendError("There was an unkown error. We're looking into it ")
    }
  }
}

const PayForFree = () => {
  const { t } = useTranslation()
  const paymentError = useOrder('paymentError')
  const paymentPending = useOrder('paymentPending')
  const dispatch = useTypedDispatch()
  const order = usePreparedOrder()

  const payWithNoMoney = () => {
    dispatch({
      type: 'PAY_FOR_ORDER',
      payload: makePaymentForFree(order),
    })
  }

  return (
    <View>
      {paymentError && <Warning style={{ marginTop: 10 }}>{paymentError.message}</Warning>}
      <Spacer height={10} />
      <PayButton
        label={t('Place order')}
        isPaying={paymentPending}
        formComplete={true}
        amount={priceToPounds(0)}
        pay={payWithNoMoney}
      />
      <Spacer height={15} />
    </View>
  )
}

export default PayForFree
