import { memo, useState } from 'react'

import { Error, Spacer, NumericCodeInput, View, B, Text } from '@cnd/elements'
import { useTypedDispatch, useTypedSelector } from '@store'
import api from '@services/api'
import useUser from '@store/useUser'
import { loginAction } from '@store/userMiddleware'
import { setDoubleSidebarBottom } from '@store/actions'
import ColoredButton from '@components/ColoredButton'
import { FaArrowDown } from 'react-icons/fa6'
import colors from '@constants/colors'

const LoginModalConfirm = () => {
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState('')

  const loading = useTypedSelector((state) => state.user.pending)

  const userId = useUser('userId')
  const dispatch = useTypedDispatch()
  const tempPhoneNumber = useTypedSelector((state) => state.user.tempPhoneNumber)
  const [loadingState, setLoadingState] = useState('')

  const setCodeFromUserInput = (userCode) => {
    setCodeError('')
    setCode(userCode)
  }

  const tapToResend = async () => {
    try {
      setLoadingState('SENDING')

      await api.post(`/issue_verification_code`, { phoneNumber: tempPhoneNumber, userId })
    } catch (error) {}
    setLoadingState('')
  }

  const confirm = async () => {
    try {
      setLoadingState('CHECKING')
      setCodeError('')

      const { data } = await api.post(`/verify_verification_code`, {
        phoneNumber: tempPhoneNumber,
        code,
        userId,
      })

      const { accessToken, userId: localUserId } = data
      await dispatch(loginAction({ accessToken, userId: localUserId }))
      dispatch(setDoubleSidebarBottom({ isOpen: false, content: '' }))
    } catch (error) {
      console.warn({ confirm: error })
      if (error.name === 'AxiosError' && error?.response?.data?.error === 'Code has expired')
        setCodeError('Your code has expired, please tap below to resend a new one')
      else if (error.name === 'AxiosError' && error?.response?.data?.error === 'Code is incorrect')
        setCodeError('The code you entered is incorrect, please check and try again')
      else setCodeError('There was a problem validating your phone number, please try again later')
    }
    setLoadingState('')
  }

  const goBack = () => {
    dispatch({ type: 'SET_AUTH_STATUS', payload: 'requesting-number' })
  }

  const confirmDisabled = code.length !== 6 || !!loadingState ? true : false
  return (
    <div style={{ height: '100%', paddingTop: 45 }}>
      <View style={{ paddingLeft: 25, position: 'absolute', right: 20 }}>
        <ColoredButton onClick={goBack} size={45}>
          <FaArrowDown />
        </ColoredButton>
      </View>
      <Spacer height={70} />
      <View center>
        <B size={32} style={{ lineHeight: '36px' }}>
          Enter Code
        </B>
        <Spacer height={20} />
        <Text style={{ display: 'block', width: 260, textAlign: 'center' }}>
          Enter the code we sent in a text message to <B>{tempPhoneNumber}</B>
        </Text>
        <Spacer height={20} />

        <NumericCodeInput onChange={setCodeFromUserInput} onPresEnterKey={confirm} />
        <Spacer height={25} />

        {!!codeError && <Error>{codeError}</Error>}

        <ColoredButton onClick={confirm} disabled={confirmDisabled} auto size={50} color={colors.nBlack()}>
          <Text weight={500} style={{ paddingInline: 18 }} size={18} color="white">
            {loading || loadingState !== '' ? loadingState : 'Confirm'}
          </Text>
        </ColoredButton>
        <Spacer height={15} />
        <div
          onClick={tapToResend}
          style={{
            padding: 15,
            textAlign: 'center',
            fontSize: 16,
            cursor: 'pointer',
          }}
        >
          Didn't get a text? <br />
          <span
            style={{
              color: 'darkred',
              textDecoration: 'underline',
            }}
          >
            Tap to resend.
          </span>
        </div>
        <Spacer height={255} />
      </View>
    </div>
  )
}

export default memo(LoginModalConfirm)
