import colors from '@constants/colors'
import { SubHeading, Text, View } from '@cnd/elements'

import { setDoubleSidebarBottomIsOpen, setSidebarBottomIsOpen } from '@store/actions'
import { useTypedDispatch, useTypedSelector } from '@store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'

const On = ({ color, background, children }) => {
  return (
    <View
      style={{
        padding: '3px 9px',
        borderRadius: 7,
        boxShadow: '0 0 10px -6px black',
        display: 'inline-block',
        border: '2px solid white',
        cursor: 'pointer',
        background,
      }}
    >
      <Text weight={600} color={color}>
        {children}
      </Text>
    </View>
  )
}

const Help = () => {
  const dispatch = useTypedDispatch()

  const data = useTypedSelector((state) => state.sidebarBottom.data)

  const close = () => {
    dispatch(setDoubleSidebarBottomIsOpen(false))
  }
  const goToWhatsApp = () => {
    window.open(
      `https://wa.me/+447930884657?text=Help%2C%20I%20need%20somebody...with%20order%20that%20has%20id=${data?.id}%21`
    )
  }

  const callUs = () => {
    window.open(`tel:+447930884657`)
  }

  return (
    <View style={{ padding: '0px 10px 15px 10px', position: 'relative' }} center>
      <SubHeading>We're here to help</SubHeading>
      <FontAwesomeIcon
        style={{ right: 0, position: 'absolute', top: 0, padding: 10 }}
        icon={faClose}
        fontSize={32}
        onClick={close}
      />
      <View row onClick={goToWhatsApp} spaceBetween center style={{ maxWidth: 280, padding: 10 }}>
        <Text style={{ padding: 20 }}>On</Text>
        <On color={'white'} background={'#11ba66'}>
          WhatsApp
        </On>
      </View>

      <View row onClick={callUs} spaceBetween center style={{ maxWidth: 280, padding: 10 }}>
        <Text style={{ padding: 20 }}>By phone</Text>
        <On color={'black'} background={colors.yellow}>
          07930 884 657
        </On>
      </View>
    </View>
  )
}

export default Help
