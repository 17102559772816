import { View } from '@cnd/elements'

const CheckoutPage = ({ children }) => {
  return (
    <View style={{ maxWidth: 550, margin: '0 auto', width: '100%', height: '100%', position: 'relative' }}>
      {children}
    </View>
  )
}
export default CheckoutPage
