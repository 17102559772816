import Image from 'next/image'
import React from 'react'

const GoogleMapPin = ({ icon }) => {
  return (
    <div style={{ height: 40, position: 'relative' }}>
      <Image
        alt={'google map pin'}
        style={{
          height: 40,
          position: 'absolute',
          top: '-40px',
          left: '-20px',
        }}
        width={30}
        height={40}
        src={icon}
      />
    </div>
  )
}

export default GoogleMapPin
