import api from '@services/api'

import { getMembershipFromItems } from '@cnd/common/functions/menu'
import { Order, OrderStatuses } from '@cnd/common/types'
import { createOrderStatus } from '@cnd/common/functions/payment'
import { STRIPE_ENV } from '@constants/stripe'

export const updateOrderStatus = async (order: Order, status: OrderStatuses) => {
  const newStatus = createOrderStatus(status)
  await api.put(`/orders/${order.id}/status/${newStatus.id}`, { status: newStatus })
}

export const paymentMethodSteps = {
  card: {},
  applePay: {},
  free: {},
}

export const createSubscriptionIfInBasket = async ({ order }): Promise<{ order: any; user: any }> => {
  const membershipInOrder = getMembershipFromItems(order.items)
  if (!membershipInOrder) return { order, user: {} }

  let { data } = await api.post(`/CreateSubscription`, {
    membership: membershipInOrder,
    order,
    env: STRIPE_ENV,
  })

  return { order: data.order, user: data.user }
}
