import React, { memo, useEffect, useState } from 'react'

import { Spacer, View } from '@cnd/elements'
import { closeSidebarBottom } from '@store/actions'
import LaterDelivery from './SlideOvers/LaterDelivery'
import AddDeliveryLocation from './SlideOvers/AddDeliveryLocation'
import PaymentMethodPicker from './SlideOvers/PaymentMethodPicker'
import AddPaymentMethod from './SlideOvers/AddPaymentMethod'
import Help from './SlideOvers/Help'
import LoginModal from './Login/LoginModal'
import { useTypedDispatch, useTypedSelector } from '@store'
import ChooseMembership from './SlideOvers/ChooseMembership'
import SelectTimeWorked from './SlideOvers/SelectTimeWorked'
import { useAnimation } from '@cnd/elements'

import useKeyboard from '@hooks/useKeyboard'
import { useSafeAreaInsets } from './SafeAreaView'
import { useWindowSize } from 'usehooks-ts'
import AccountMenu from './AccountMenu/AccountMenu'
import { indexOf } from 'ramda'
import Checkout from '@components/CheckoutSteps/Checkout'
import { AnimatePresence } from '@cnd/elements'
import { absoluteFillObject } from '@services/StyleSheet'
import { CSSProperties } from 'react'
import { isApp } from '@services/platform'
import EditNewUserDeliveryDate from './SlideOvers/EditNewUserDeliveryDate'
import EditNewUserDeliveryTime from './SlideOvers/EditNewUserDeliveryTime'

let LoadStripe

if (isApp) {
  LoadStripe = require('./LoadStripeApp').default
} else {
  LoadStripe = require('./LoadStripeWeb').default
}

const hideKeybordspaceron = ['Checkout']

const calculateModalPositionData = (windowHeight, topInset, bottomInset) => {
  const topMargin = windowHeight > 700 ? 85 : windowHeight < 400 ? 0 : 55
  return { height: windowHeight - topInset - bottomInset - topMargin }
}

export const PageWithSidebarBottom = ({ pageId }) => {
  const [keyboardHeight] = useState(300)
  const { bottom, top } = useSafeAreaInsets()
  const { height } = useWindowSize()

  const { isOpen, content } = useTypedSelector((state) => state.sidebarBottom)
  const dispatch = useTypedDispatch()
  const controls = useAnimation()
  const { Keyboard } = useKeyboard()

  const closeSidebar = (e) => {
    console.log('ssss')
    if (e?.stopPropagation) {
      console.log('RRRR')
      e?.stopPropagation()
    }
    Keyboard.hide()
    dispatch(closeSidebarBottom())
  }

  useEffect(() => {
    if (isOpen) controls.start('visible')
    if (!isOpen) controls.start('hidden')
  }, [isOpen])

  const { height: modalHeight } = calculateModalPositionData(height, top, bottom)

  return (
    <>
      <View
        motion
        key={`sidebar_bottom_content__${pageId}`}
        exit="hidden"
        initial={{ height: 0 }}
        animate={{ height: isOpen ? modalHeight : 0 }}
        style={{
          background: '#fff',
          zIndex: 22,
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          height: modalHeight,
          overflow: 'hidden',
          borderTopLeftRadius: 7,
          borderTopRightRadius: 7,
          borderTop: `4px solid #2D1619`,
        }}
      >
        <View
          motion
          style={{ overflow: 'scroll', height: modalHeight }}
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
        >
          {content === 'LaterDelivery' && <LaterDelivery />}
          {content === 'AddDeliveryLocation' && <AddDeliveryLocation />}
          {content === 'EditNewUserDeliveryDate' && <EditNewUserDeliveryDate />}
          {content === 'EditNewUserDeliveryTime' && <EditNewUserDeliveryTime />}
          {content === 'SelectTimeWorked' && <SelectTimeWorked />}
          {content === 'Help' && <Help />}
          {content === 'PaymentMethodPicker' && (
            <LoadStripe>
              <PaymentMethodPicker />
            </LoadStripe>
          )}
          {content === 'AddPaymentMethod' && (
            <LoadStripe>
              <AddPaymentMethod />
            </LoadStripe>
          )}
          {content === 'LoginModal' && <LoginModal />}
          {content === 'Checkout' && <Checkout />}
          {content === 'ChooseMembership' && <ChooseMembership />}
          {content === 'Menu' && <AccountMenu />}
          {indexOf(content, hideKeybordspaceron) === -1 && <Spacer height={keyboardHeight} />}
        </View>
      </View>

      {isOpen && (
        <View
          motion
          onClick={closeSidebar}
          key={`sidebar_bottom_overlay__${pageId}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: isOpen ? 0.8 : 0 }}
          transition={{ type: 'spring' }}
          style={{
            pointerEvents: 'all',
            position: 'absolute',
            zIndex: 20,
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            backgroundColor: `#2E161E`,
          }}
        />
      )}
    </>
  )
}

interface ShrinkOnSidebarOpenProps {
  style?: CSSProperties
  children: any
  background?: string
}

const variants = {
  isOpen: {
    scale: 0.94,
    transition: { type: 'spring', stiffness: 100 },
  },
  isClosed: {
    scale: 1,
    transition: { type: 'spring', stiffness: 100 },
  },
}

export const ShrinkOnSidebarOpen = memo(({ children }: ShrinkOnSidebarOpenProps) => {
  const isOpen = useTypedSelector((state) => state.sidebarBottom.isOpen)

  return (
    <AnimatePresence>
      <View style={absoluteFillObject} motion animate={isOpen ? `isOpen` : `isClosed`} variants={variants}>
        {children}
      </View>
    </AnimatePresence>
  )
})
