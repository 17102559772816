import { useTypedSelector } from '@store'
import { InitialState } from './initialState'

const useClosestLocation = () =>
  useTypedSelector((s) => s.locations?.closestToUser) ||
  ({
    preorderDeliveryDays: [],
    hoursAferNowCanPreorder: 0,
  } as InitialState['locations']['closestToUser'])

export default useClosestLocation
