import {
  BusinessLocations,
  DaysOfTheWeekendArray,
  DaysOfTheWorkWeekArray,
  LocationNames,
} from '@cnd/common/types'

export interface ShiftType {
  id: string
  name: string
  location: LocationNames
  available: DaysOfTheWorkWeekArray | DaysOfTheWeekendArray
  start: string
  end: string
  payStart: string
  payEnd: string
  expenses: boolean
}

export const shifts: ShiftType[] = [
  {
    id: 'Weekday Morning',
    name: 'Weekday Morning',
    location: 'DK',
    available: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
    start: '07:00',
    end: '15:00',
    payStart: '06:50',
    payEnd: '15:05',
    expenses: true,
  },
  {
    id: 'Weekday Shop Start',
    name: 'Weekday Shop Start',
    location: 'shop',
    available: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
    start: '09:30',
    end: '19:00',
    payStart: '09:20',
    payEnd: '19:10',
    expenses: false,
  },
  {
    id: 'Weekday Shop Lunch',
    name: 'Weekday Shop Lunch',
    location: 'shop',
    available: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
    start: '11:00',
    end: '19:00',
    payStart: '10:50',
    payEnd: '19:10',
    expenses: false,
  },
  {
    id: 'Weekday Shop Evening',
    name: 'Weekday Shop Evening',
    location: 'shop',
    available: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
    start: '15:00',
    end: '19:00',
    payStart: '14:50',
    payEnd: '19:10',
    expenses: false,
  },
  {
    id: 'Weekend Morning',
    name: 'Weekend Morning',
    location: 'DK',
    available: ['Sat', 'Sun'],
    start: '08:00',
    end: '20:00',
    payStart: '07:50',
    payEnd: '20:15',
    expenses: true,
  },
  {
    id: 'Weekend Shop Start',
    name: 'Weekend Shop Start',
    location: 'shop',
    available: ['Sat', 'Sun'],
    start: '11:00',
    end: '20:00',
    payStart: '10:50',
    payEnd: '20:15',
    expenses: false,
  },
]

export const locations: BusinessLocations = {
  shop: { id: 'shop', name: 'shop', latitude: 51.50790495905322, longitude: -0.12344544317893316 },
  DK: { id: 'DK', name: 'DK', latitude: 51.495727, longitude: -0.096889 },
}
