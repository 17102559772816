import { View } from '@cnd/elements'
import CrediCardIcon from './CrediCardIcon'
import { PaymentMethod } from '@cnd/common/types'

type SavedCardProps = {
  card: Partial<PaymentMethod>
}

const SavedCardUI = ({ card: { brand, last4 = '' } }: SavedCardProps) => {
  return (
    <View row center>
      <CrediCardIcon brand={brand} />
      <View style={{ fontWeight: 600, marginRight: 5 }}>{brand}</View> {!!last4 && <>{last4}</>}
    </View>
  )
}
export default SavedCardUI
