import { View } from '@cnd/elements'
import Image from 'next/image'

const CrediCardIcon = ({ brand }) => {
  let style = {}

  if (brand === 'Google Pay')
    return (
      <View style={{ height: 34, width: 87, overflow: 'hidden' }}>
        <Image
          src={`/google-pay-mark_800.svg`}
          width={80}
          height={60}
          alt="Google Pay"
          style={{ position: 'relative', top: -10 }}
        />
      </View>
    )

  switch (brand) {
    case 'Visa':
      style['backgroundPosition'] = '159px 69px'
      break
    case 'Apple Pay':
      style['backgroundPosition'] = '74px 61px'
      style['backgroundSize'] = 37
      style['backgroundImage'] = 'url(/apple-pay.webp)'
      break
    case 'MasterCard':
      style['backgroundPosition'] = '-116px 67px'
      style['backgroundSize'] = 226
      break
    case 'American Express':
      style['backgroundPosition'] = '68px 69px'
      break
    default:
      style['backgroundPosition'] = 0
      break
  }

  return (
    <View
      style={{
        backgroundImage: ' url(/cards.webp)',
        width: 37,
        height: 22,
        backgroundSize: 237,
        backgroundRepeat: 'repeat',
        backgroundPositionY: 'center',
        borderRadius: 4,
        marginRight: 10,
        ...style,
      }}
    />
  )
}

export default CrediCardIcon
