import { Spacer, View, sectionStyle, Button, Text, LoadingAnimation } from '@cnd/elements'
import { setDoubleSidebarBottom } from '@store/actions'

import { useTypedDispatch } from '@store'

import map from 'lodash/map'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import CrediCardIcon from '@components/CheckoutSteps/PlaceOrderForm/CrediCardIcon'
import useUser from '@store/useUser'
import { isApp } from '@services/platform'
import ColoredButton from '@components/ColoredButton'
import ColoredCard from '@components/ColoredCard'
import colors from '@constants/colors'
let usePaymentMethods
if (isApp) {
  usePaymentMethods = require('@store/usePaymentMethodsApp')
} else {
  usePaymentMethods = require('@store/usePaymentMethodsWeb')
}

const canRemove = (id) => {
  if (id === 'apple-pay') return false
  if (id === 'google-pay') return false
  return true
}

const PaymentMethodPicker = () => {
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()
  const pending = useUser('paymentMethodsPending')
  const userId = useUser('userId')

  const { selectedPaymentMethod, paymentMethods } = usePaymentMethods.default()

  const addPaymentMethod = () => {
    dispatch(setDoubleSidebarBottom({ isOpen: true, content: 'AddPaymentMethod' }))
  }
  const close = () => dispatch(setDoubleSidebarBottom({ isOpen: false, content: '' }))

  const selectPaymentMethod = (paymentMethodId) => {
    if (pending) return
    dispatch({
      type: 'SET_USER_PAYMENT_METHOD',
      payload: usePaymentMethods.setPaymentMethod({ paymentMethodId, userId }),
    })
    close()
  }

  const initRemoveCard = (id) => {
    const confirmed = confirm(t(`Are you sure you want to remove your payment method?`))
    if (confirmed)
      dispatch({
        type: 'REMOVE_USER_PAYMENT_METHOD',
        payload: usePaymentMethods.removePaymentMethod({ paymentMethodId: id, userId }),
      })
  }

  return (
    <View
      style={{
        padding: '10px 10px 15px 10px',
        position: 'relative',
        maxWidth: 550,
        margin: '0 auto',
        width: '100%',
      }}
    >
      <View center style={{ padding: 20 }}>
        <Text weight={600} size={18}>
          {t('Select payment method')}
        </Text>
        <FontAwesomeIcon
          style={{ position: 'absolute', top: 10, right: 15, padding: 10 }}
          icon={faXmark}
          fontSize={26}
          onClick={close}
        />
      </View>

      <Spacer height={15} />
      {pending && <LoadingAnimation />}

      {!pending &&
        map(paymentMethods, ({ id, brand, last4 }) => (
          <ColoredCard
            key={id}
            row
            onClick={() => selectPaymentMethod(id)}
            style={{ cursor: 'pointer', height: 60 }}
            spaceBetween
            color={id === selectedPaymentMethod?.id ? colors.nnWhite : colors.nWhite}
          >
            <View row center>
              {canRemove(id) && (
                <View
                  center
                  style={{ paddingInline: 10, paddingLeft: 10, paddingRight: 15 }}
                  onClick={(e) => {
                    e.stopPropagation()
                    initRemoveCard(id)
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} color="darkred" />
                </View>
              )}
              <CrediCardIcon brand={brand} />
              <View row style={{ fontWeight: 600 }}>
                <Text weight={500} style={{ marginRight: 7 }}>
                  {brand}
                </Text>
                {!!last4 && <Text>{last4}</Text>}
              </View>
            </View>
            <View row alignCenter>
              {!(id === selectedPaymentMethod?.id) && <Text>{t('select')}</Text>}
              {id === selectedPaymentMethod?.id && (
                <View row center style={{ padding: '2px 7px', paddingRight: 10 }}>
                  <Text size={14} weight={500} color={'#6caa32'} style={{ paddingRight: 10 }}>
                    {t('selected')}
                  </Text>
                  <FontAwesomeIcon icon={faCheck} color="#6caa32" />
                </View>
              )}
            </View>
          </ColoredCard>
        ))}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 15 }}>
        <ColoredButton auto small open onClick={addPaymentMethod} size={45}>
          <Text weight={500} style={{ paddingInline: 20 }}>
            {t('Add payment method')}
          </Text>
        </ColoredButton>
      </div>
    </View>
  )
}

export default PaymentMethodPicker
