import keys from 'lodash/keys'
import map from 'lodash/map'
import api from '@services/api'

export const addItemToOrder = (item) =>
  ({
    type: 'ADD_ITEM_TO_ORDER',
    payload: item,
  }) as const
export const removeItemFromOrderByKey = (itemKey: string) =>
  ({
    type: 'REMOVE_ITEM_FROM_ORDER_BY_KEY',
    payload: itemKey,
  }) as const

export const removeItemFromOrderByItemId = (items, itemId) => {
  let itemKeyToRemove = null
  map(keys(items), (itemKey) => {
    if (items[itemKey].id === itemId) itemKeyToRemove = itemKey
  })
  if (itemKeyToRemove) return removeItemFromOrderByKey(itemKeyToRemove)
  return null
}

export const setPreOrder = (preOrder) =>
  ({
    type: 'UPDATE_ORDER_PREORDER',
    payload: preOrder,
  }) as const

export const setPreOrderDateTime = (dateTime = null) =>
  ({
    type: 'UPDATE_ORDER_PREORDER_DATE_TIME',
    payload: dateTime,
  }) as const

export const setOrderPayment = (payment) =>
  ({
    type: 'UPDATE_ORDER_PAYMENT',
    payload: payment,
  }) as const

export const addReward = (reward) =>
  ({
    type: 'ADD_REWARD_TO_ORDER',
    payload: reward,
  }) as const

export const removeReward = (id) =>
  ({
    type: 'REMOVE_REWARD_FROM_ORDER',
    payload: id,
  }) as const

export const setProposedDeliveryTimeAndLimit = (
  proposedDeliveryTimeLower = null,
  proposedDeliveryTimeUpper = null
) =>
  ({
    type: 'UPDATE_ORDER_DELIVERY_TIME',
    payload: new Promise(async (resolve, reject) => {
      try {
        const { data: deliveryTimes, status } = await api.post(`/DeliveryTime`, {
          orderFor: 'preOrder',
          proposedDeliveryTimeLower,
          proposedDeliveryTimeUpper,
        })

        if (status !== 200) throw { error: { message: 'Unabled to get order delivery time' } }

        resolve({
          proposedDeliveryTimeUpper: deliveryTimes.proposedDeliveryTimeUpper,
          proposedDeliveryTimeLower: deliveryTimes.proposedDeliveryTimeLower,
          proposedMinutesUntilDeliveryUpper: false,
          proposedMinutesUntilDeliveryLower: false,
        })
      } catch (error) {
        reject(error)
      }
    }),
  }) as const

export const setProposedMinutesUntilDelivery = () =>
  ({
    type: 'UPDATE_ORDER_DELIVERY_TIME',
    payload: new Promise(async (resolve, reject) => {
      try {
        const { data: deliveryTimes, status } = await api.post(`/DeliveryTime`, {
          orderFor: 'now',
        })

        if (status !== 200) throw { error: { message: 'Unabled to get order delivery time' } }

        resolve({
          proposedMinutesUntilDeliveryUpper: deliveryTimes.proposedMinutesUntilDeliveryUpper,
          proposedMinutesUntilDeliveryLower: deliveryTimes.proposedMinutesUntilDeliveryLower,
          proposedDeliveryTimeUpper: false,
          proposedDeliveryTimeLower: false,
        })
      } catch (error) {
        reject(error)
      }
    }),
  }) as const
