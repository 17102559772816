import { Spacer, FormContainer, View, Text } from '@cnd/elements'

import DeliverTo from './DeliverTo'
import UpdateMe from './UpdateMe'

import CollectFrom from './CollectFrom'
import Cutlery from './Cutlery'
import StripeSecureLogo from './StripeSecureLogo'
import BasketBreakdown from './BasketBreakdown'

import PaymentMethods from './PaymentMethods'
import useSyncUser from '@hooks/useSyncUser'
import ForOurNeighbours from './ForOurNeighbours'
import useOrder from '@store/useOrder'
import useUser from '@store/useUser'
import useKeyboard from '@hooks/useKeyboard'
import { VERSION } from '@services/version'
import { isValidDeliveryLocation } from '@cnd/common/functions/payment'

const PlaceOrderForm = () => {
  useSyncUser()
  const deliveryLocation = useOrder('deliveryLocation')
  const isCollection = useOrder('isCollection')
  const phoneNumber = useUser('phoneNumber')
  const { Keyboard } = useKeyboard()

  let hideStyle = {}

  if (!isCollection && !isValidDeliveryLocation(deliveryLocation))
    hideStyle = { opacity: 0, pointerEvents: 'none' }

  const hasPhoneNumber = !!phoneNumber

  return (
    <View style={hideStyle} onClick={() => Keyboard.hide()}>
      <UpdateMe />

      {/* <BasketBreakdown enabled={hasPhoneNumber} /> */}
      {/* <Spacer height={20} />
      <ForOurNeighbours enabled={hasPhoneNumber} /> */}
      <Spacer height={20} />
      <PaymentMethods enabled={hasPhoneNumber} />
      <Text center size={10} color="rgba(0,0,0,0.15)" style={{ position: 'relative', top: 30 }}>
        v{VERSION}
      </Text>
      {/* <StripeSecureLogo /> */}
      <Spacer height={145} />
    </View>
  )
}
export default PlaceOrderForm
