import { priceToPounds } from '@cnd/common/functions/menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Free, View } from '@cnd/elements'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import { useTranslation } from 'react-i18next'

const removeNegativeOptions = (selectedIngredientsArray) => {
  const newSelectedIngredientsArray = selectedIngredientsArray.filter((ingredient) => {
    if (ingredient.selectedOption && ingredient.selectedOption.value != 0) return true
    return false
  })
  return newSelectedIngredientsArray
}

const capitalFirstLetter = (str) => str[0].toUpperCase() + str.substr(1, str.length - 1)

const createIngredientsNiceString = (selectedIngredients) => {
  if (!selectedIngredients) return false
  let niceString = ''
  const selectedIngredientsArray = Object.keys(selectedIngredients).map((key) => ({
    ...selectedIngredients[key],
    key,
  }))
  const filteredSelectedIngredientsArray = removeNegativeOptions(selectedIngredientsArray)
  const numberOfSelectedIngredients = filteredSelectedIngredientsArray.length
  filteredSelectedIngredientsArray.map((ingredient, i) => {
    const { shortDescription, value } = ingredient.selectedOption
    if (value !== 0) {
      let glueString = ', '
      if (numberOfSelectedIngredients - 1 === i) {
        glueString = ' and '
      }

      if (!niceString) {
        niceString = `${capitalFirstLetter(shortDescription)}`
      } else {
        niceString = `${niceString}${glueString}${shortDescription}`
      }
    }
  })
  return niceString
}

interface LineItemProps {
  item: any
  removeItem?: any
}

const LineItem = ({
  item: { key, price, selectedIngredients, title, mealDeal, superPriceWithOptions },
  removeItem,
}: LineItemProps) => {
  const { t } = useTranslation()
  const removeLineItem = () => {
    removeItem(key)
  }

  const ingredientsNiceString = createIngredientsNiceString(selectedIngredients)

  return (
    <View row spaceBetween style={{ width: '100%' }}>
      <View row style={{ alignItems: 'center' }}>
        {!!removeItem ? (
          <View
            style={{
              padding: '12px 15px 12px 15px',
              margin: 8,
              borderRadius: 20,
              background: `rgba(255, 230, 230, 0.54)`,
            }}
            onClick={removeLineItem}
          >
            <FontAwesomeIcon fontSize={14} icon={faXmark} />
          </View>
        ) : (
          <View style={{ height: 45 }} />
        )}

        <View
          style={{
            width: 30,
            height: 30,
            background: '#EDEDED',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
          }}
        >
          {1}
        </View>
        <View style={{ marginLeft: 10 }}>
          <View style={{ fontSize: 16 }}>{t(title)}</View>
          {!!ingredientsNiceString && <span className={'options-description'}>{ingredientsNiceString}</span>}
          {mealDeal && <span className={'options-description'}>{mealDeal.description}</span>}
        </View>
      </View>
      <View style={{ fontSize: 14, justifyContent: 'center', paddingRight: 15 }}>
        {price === 0 ? (
          <Free style={{ position: 'relative', right: -10 }} />
        ) : (
          <View center>
            {superPriceWithOptions - price > 0 && (
              <span style={{ fontWeight: 300, textDecoration: 'line-through', fontSize: 12 }}>
                {'£' + priceToPounds(superPriceWithOptions)}
              </span>
            )}
            <span style={{ fontWeight: 300 }}>{'£' + priceToPounds(price)}</span>
          </View>
        )}
      </View>
    </View>
  )
}
export default LineItem
