import { View } from '@cnd/elements'
import colors from '@constants/colors'

const ColoredButton = ({
  onClick = null,
  children,
  margin = 10,
  color = colors.nnWhite,
  style = {},
  size = 60,
  colorOffset = 2,
  borderWidth = 2,
  full = false,
  auto = false,
  disabled = false,
  disabledClick = () => {},
  ...rest
}) => {
  return (
    <View
      whileTap={{ scale: 0.8 }}
      motion
      onClick={disabled ? disabledClick : onClick}
      style={{
        position: 'relative',
        marginRight: margin + colorOffset,
        marginBottom: margin + colorOffset,
        marginTop: margin,
        marginLeft: margin,
        opacity: disabled ? 0.7 : 1,
        width: full ? '100%' : auto ? 'auto' : size,
      }}
    >
      <View
        style={{
          background: color,
          position: 'absolute',
          top: colorOffset,
          bottom: -colorOffset,
          left: colorOffset,
          right: -colorOffset,
          borderRadius: 500,
          zIndex: 0,
          width: full ? '100%' : auto ? 'auto' : size,
          height: size,
        }}
      />
      <View
        center
        style={{
          padding: 10,
          borderRadius: 500,
          border: `${borderWidth}px solid ${colors.nBlack(0.9)}`,
          zIndex: 1,
          position: 'relative',
          width: full ? '100%' : auto ? 'auto' : size,
          height: size,
        }}
        {...rest}
      >
        {children}
      </View>
    </View>
  )
}

export default ColoredButton
