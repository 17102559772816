import { View } from '@cnd/elements'

const MenuItemSeparator = () => {
  return (
    <View center style={{ width: '100%' }}>
      <View
        style={{
          height: 1,
          width: '85%',
          marginTop: 5,
          marginBottom: 5,
          backgroundColor: `rgba(0,0,0,0.07)`,
        }}
      />
    </View>
  )
}

export default MenuItemSeparator
