import { View, ScrollView } from '@cnd/elements'

import useSyncUser from '@hooks/useSyncUser'
import OrdersList from './OrdersList'

const AccountDietaryRequirements = () => {
  useSyncUser()

  return (
    <View
      motion
      key={`account-my-orders`}
      initial={{ opacity: 0, x: -70 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -70 }}
      style={{ width: '100%' }}
    >
      <ScrollView paddingTop={0} absolute={false}>
        <OrdersList />
      </ScrollView>
    </View>
  )
}
export default AccountDietaryRequirements
