import { SubHeading, View, LoadingAnimation, Button, Text } from '@cnd/elements'
import SavedCard from './SavedCard'
import { useTypedDispatch } from '@store'
import { setDoubleSidebarBottom, setSidebarBottom } from '@store/actions'

import { keys } from 'lodash'

import useOrder from '@store/useOrder'
import { useTranslation } from 'react-i18next'
import useUser from '@store/useUser'
import { isApp } from '@services/platform'
import CheckoutSubtitle from '@components/CheckoutSteps/CheckoutSubtitle'
import cardStyle from '@components/CheckoutSteps/cardStyle'
import SavedCardUI from './SavedCardUI'
import ColoredCard from '@components/ColoredCard'

let usePaymentMethods
if (isApp) {
  usePaymentMethods = require('@store/usePaymentMethodsApp').default
} else {
  usePaymentMethods = require('@store/usePaymentMethodsWeb').default
}

const PaymentMethods = ({ enabled = true }) => {
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()
  const pending = useUser('paymentMethodsPending')
  const { selectedPaymentMethod } = usePaymentMethods()

  const totalIncludingDelivery = useOrder('totalIncludingDelivery')
  const isFree = totalIncludingDelivery === 0

  const openAddPaymentMethodModal = () => {
    dispatch(setDoubleSidebarBottom({ isOpen: true, content: 'AddPaymentMethod' }))
  }
  const openChangePaymentMethodModal = () => {
    dispatch(setDoubleSidebarBottom({ isOpen: true, content: 'PaymentMethodPicker' }))
  }

  if (isFree) return null

  return (
    <View style={!enabled ? { opacity: 0, pointerEvents: 'none' } : {}}>
      <CheckoutSubtitle>{t('How would you like to pay?')}</CheckoutSubtitle>

      {!selectedPaymentMethod && (
        <View center style={{ padding: 15 }}>
          <Button small onClick={openAddPaymentMethodModal} style={{ fontWeight: 400, padding: 15 }}>
            {t('Add card')}
          </Button>
        </View>
      )}

      {pending ? (
        <View center style={{ width: '100%', padding: 15 }}>
          <LoadingAnimation style={{ height: 15, width: 3, radius: 4 }} />
        </View>
      ) : (
        <>
          {selectedPaymentMethod && (
            <ColoredCard
              style={{ cursor: 'pointer', padding: 15 }}
              spaceBetween
              row
              center
              onClick={openChangePaymentMethodModal}
            >
              <SavedCardUI key={selectedPaymentMethod.id} card={selectedPaymentMethod} />
              <Text size={14} weight={400}>
                Change
              </Text>
            </ColoredCard>
          )}
        </>
      )}
    </View>
  )
}

export default PaymentMethods
