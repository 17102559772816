const hasPasedStatus = (statusToPass, status) => {
  const statuses = ['CONFIRMED', 'PREPARING', 'READY_FOR_COLLECTION', 'OUT_FOR_DELIVERY', 'DELIVERED']

  const indexOfStatusToPass = statuses.indexOf(statusToPass)
  const indexOfStatus = statuses.indexOf(status)

  if (indexOfStatusToPass > indexOfStatus) return false
  return true
}
export default hasPasedStatus
