import { Text, View } from '@cnd/elements'
import { useTypedDispatch } from '@store'
import { setDoubleSidebarBottom } from '@store/actions'
import { IoIosHelpBuoy } from 'react-icons/io'

const OrderHelp = ({ orderId }) => {
  const dispatch = useTypedDispatch()

  const openHelp = () => {
    dispatch(
      setDoubleSidebarBottom({
        data: { id: orderId?.substring(0, 8) },
        isOpen: true,
        height: '36vh',
        content: 'Help',
      })
    )
  }

  return (
    <View motion whileTap={{ scale: 0.8 }} onClick={openHelp} row center>
      <Text size={14} weight={400} style={{ marginRight: 6 }}>
        Order help
      </Text>
      <IoIosHelpBuoy fontSize={19} />
    </View>
  )
}

export default OrderHelp
