import { removeEmpty } from '@cnd/redash/dist/removeEmpty'
import { useCallback } from 'react'

import api from '@services/api'
import useUser from '@store/useUser'
import { User } from '@cnd/common/types'
import { createError } from '@cnd/redash'
import { curry, dissoc } from 'ramda'

export const updateUser = curry((userId, user: Partial<User>) => {
  if (!userId) throw createError('Missing user Id')
  return api.post(`users/${userId}`, { user: removeEmpty(dissoc('userId', user)) })
})

const useUpdateUser = () => {
  const userId = useUser('userId')
  const postUser = useCallback(updateUser(userId), [userId])

  return postUser
}

export default useUpdateUser
