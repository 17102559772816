import { Spacer, View } from '@cnd/elements'
import { memberships, priceToPounds } from '@cnd/common/functions/menu'
import { addItemToOrder } from '@store/orderActions'
import { setSidebarBottom } from '@store/actions'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { useTypedDispatch } from '@store'

const BackButton = ({ onClick }) => (
  <div style={{ padding: 20 }} onClick={onClick}>
    <View
      style={{
        borderRadius: 20,
        width: 40,
        borderColor: '#E8E8E8',
        borderStyle: 'solid',
        background: 'white',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Image src={'./back_arrow.webp'} width={36} height={36} alt={'back button'} />
    </View>
  </div>
)

const ChooseMembership = () => {
  //const membershipState = useUser('membership').shortname
  const membershipState = 'hiro'
  const router = useRouter()
  const dispatch = useTypedDispatch()

  const selectedMembership = memberships[membershipState]

  const closeModal = () => {
    dispatch(setSidebarBottom({ isOpen: false }))
  }

  const setMembership = () => {
    dispatch({ type: 'SET_USER', payload: { membership: { selected: true } } })
  }

  const join = () => {
    setMembership()
    dispatch(addItemToOrder(selectedMembership))
    closeModal()
    router.push('/PlaceOrder')
  }

  const payDouble = () => {
    setMembership()
    closeModal()
    router.push('/PlaceOrder')
  }

  const close = () => {
    closeModal()
  }

  return (
    <View
      style={{
        color: 'white',
        background: selectedMembership.color,
        position: 'relative',
        height: '100%',
        minHeight: 650,
      }}
    >
      <div
        style={{
          background: 'white',
          width: 378,
          height: 660,
          position: 'absolute',
          left: -195,
          top: -200,
          rotate: '35deg',
        }}
      />

      <View style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
        <View>
          <BackButton onClick={close} />
        </View>
        <View style={{ alignItems: 'center', flex: 1, top: -24, position: 'relative' }}>
          <Image
            priority
            src={'https://firebasestorage.googleapis.com/v0/b/indian-shack.appspot.com/o/Katsu.png?alt=media'}
            width={200}
            height={200}
            alt={'curry bowl'}
          />
          <Spacer height={20} />
          <div>
            <Image
              alt={'logo smile'}
              src={'./HiroLogoSmile.webp'}
              width={18}
              height={24}
              style={{ marginRight: 10 }}
            />
            <span style={{ fontFamily: 'Casablanca', fontSize: 34 }}>HIRO</span>
          </div>
          <Spacer height={15} />
          <div style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 20 }}>
            <div>Great food.</div>
            <div>Like deliveroo.</div>
            <div>Half price.</div>
          </div>
          <Spacer height={15} />

          <View style={{ textAlign: 'center', fontWeight: 300, fontSize: 13, width: 280 }}>
            <div>{selectedMembership.description}</div>
            <Spacer height={15} />
            <div>1 month free, then £{priceToPounds(selectedMembership.invoicePrice)} a month.</div>
          </View>
          <Spacer height={15} />
          <View
            onClick={join}
            style={{
              background: 'white',
              color: 'black',
              fontWeight: 'bold',
              fontSize: 18,
              borderRadius: 10,
              paddingLeft: 38,
              paddingRight: 38,
              paddingTop: 13,
              paddingBottom: 13,
              cursor: 'pointer',
            }}
          >
            Join {selectedMembership.name} For Free
          </View>
          <Spacer height={15} />
          <div style={{ padding: 2, fontSize: 14, borderBottom: '2px solid white' }} onClick={payDouble}>
            I want to pay double
          </div>
        </View>
      </View>
    </View>
  )
}

export default ChooseMembership
