import { View } from '@cnd/elements'
import colors from '@constants/colors'

const ColoredCard = ({
  children,
  margin = 10,
  width = 'auto',
  color = colors.nnWhite,
  style = {},
  ...rest
}) => {
  return (
    <View
      style={{
        position: 'relative',
        marginRight: margin + 3,
        marginBottom: margin + 3,
        marginTop: margin,
        marginLeft: margin,
        width,
      }}
    >
      <View
        style={{
          background: color,
          position: 'absolute',
          top: 3,
          bottom: -3,
          left: 3,
          right: -3,
          borderRadius: 10,
          zIndex: 0,
        }}
      />
      <View
        style={{
          padding: 10,
          borderRadius: 10,
          border: `1px solid ${colors.nBlack(0.2)}`,
          zIndex: 1,
          position: 'relative',
          ...style,
        }}
        {...rest}
      >
        {children}
      </View>
    </View>
  )
}

export default ColoredCard
