import api from '@services/api'
import cloneDeep from 'lodash/cloneDeep'

import { keys } from 'lodash'
import useUser from './useUser'

import { STRIPE_ENV } from '@constants/stripe'
import { createError, isError, removeWhiteSpace } from '@cnd/redash'
import useApplePayWeb from './useApplePayWeb'
import useGooglePayWeb from './useGooglePayWeb'

export const addPaymentMethod = ({ stripe, cardElement, userId, postcode }) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        token: { id: source },
      } = await stripe.createToken(cardElement, { address_zip: removeWhiteSpace(postcode) })

      const { status, data: paymentMethod } = await api.post(`/users/${userId}/paymentMethods`, {
        source,
        env: STRIPE_ENV,
      })

      if (status === 200) return resolve(paymentMethod)
      return reject(createError(`Failed to add payment method`))
    } catch (error) {
      if (error && error.name === 'AxiosError' && error?.response?.data && isError(error?.response?.data))
        return reject(createError(error.response.data))

      return reject(createError(`Failed to add payment method`))
    }
  })

export const removePaymentMethod = ({ paymentMethodId, userId }) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        status,
        data: { id },
      } = await api.delete(`/users/${userId}/paymentMethods/${paymentMethodId}?env=${STRIPE_ENV}`)
      if (status === 200) return resolve(id)
      return reject(createError(`Failed to add payment method`))
    } catch (error) {
      console.log({ removePaymentMethodError: error })
      return reject(createError(`Failed to add payment method`))
    }
  })

export const setPaymentMethod = ({ paymentMethodId, userId }) =>
  new Promise(async (resolve, reject) => {
    try {
      const { status } = await api.post(`/updateUUser`, {
        userId,
        user: {
          selectedPaymentMethod: paymentMethodId,
        },
      })
      if (status === 200) return resolve(paymentMethodId)
      return reject(createError('Failed to set payment method'))
    } catch (error) {
      return reject(createError('Failed to set payment method'))
    }
  })

const usePaymentMethodsWeb = () => {
  const savedPaymentMethods = useUser('paymentMethods')
  const selectedPaymentMethodId = useUser('selectedPaymentMethod')
  const { canUseApplePay, applePaymentMethod } = useApplePayWeb()
  const { canUseGooglePay, googlePaymentMethod } = useGooglePayWeb()

  let paymentMethods = cloneDeep(savedPaymentMethods) || {}
  if (canUseApplePay) paymentMethods[applePaymentMethod.id] = applePaymentMethod
  if (canUseGooglePay) paymentMethods[googlePaymentMethod.id] = googlePaymentMethod

  let selectedPaymentMethod = paymentMethods[selectedPaymentMethodId]
  if (!selectedPaymentMethod && canUseApplePay) selectedPaymentMethod = applePaymentMethod
  if (!selectedPaymentMethod && !canUseApplePay && canUseGooglePay)
    selectedPaymentMethod = googlePaymentMethod

  if (!selectedPaymentMethod && keys(paymentMethods).length > 0)
    selectedPaymentMethod = paymentMethods[keys(paymentMethods)[0]]

  return {
    paymentMethods,
    selectedPaymentMethod,
  }
}

export default usePaymentMethodsWeb
