import { Fragment } from 'react'
import { useTypedDispatch } from '@store'

import { FormContainer, Error, View, Spacer, Text, Button, Warning, AnimatedTextInput } from '@cnd/elements'
import { setDoubleSidebarBottom } from '@store/actions'
import StripeSecureLogo from '../CheckoutSteps/PlaceOrderForm/StripeSecureLogo'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { useRef, useState } from 'react'
import * as Sentry from '@sentry/nextjs'
import { useTranslation } from 'react-i18next'
import useUser from '@store/useUser'
import { ErrorType, createError, isError, removeWhiteSpace, safeTry } from '@cnd/redash'
import { useInterval, useScreen, useWindowSize } from 'usehooks-ts'
import api from '@services/api'
import { postcodeValidator } from 'postcode-validator'
import { map } from 'ramda'
import { isApp } from '@services/platform'
import ColoredButton from '@components/ColoredButton'
import { FaArrowDown } from 'react-icons/fa6'

let usePaymentMethods
if (isApp) {
  usePaymentMethods = require('@store/usePaymentMethodsApp')
} else {
  usePaymentMethods = require('@store/usePaymentMethodsWeb')
}

const AddPaymentMethodModal = () => {
  const { width, height } = useWindowSize()
  const isLandscape = width > height
  const stripe = useStripe()
  const pending = useUser('paymentMethodsPending')
  const rejected = useUser('paymentMethodsRejected')
  const error = useUser('paymentMethodsError')
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()
  const elements = useElements()
  const userId = useUser('userId')
  const [cardDetailsEntered, setCardDetailsEntered] = useState(false)
  const [cardNumberEntered, setCardNumberEntered] = useState(false)
  const [cardCVCEntered, setCardCVCEntered] = useState(false)
  const [cardExpEntered, setCardExpEntered] = useState(false)
  const [connected, setConnected] = useState(true)
  const [postcode, setPostcode] = useState('')
  const cardCVCElementRef = useRef(null)
  const cardExpElementRef = useRef(null)
  const cardNumberElementRef = useRef(null)
  const [postcodeValid, setPostcodeValid] = useState(null)
  const [formErrors, setFormErrors] = useState<ErrorType[]>([])

  const checkConnectivity = async () => {
    const { success } = await safeTry(async () => {
      const { data } = await api.get(`/ping`)
      return data
    })()
    if (success && !connected) setConnected(true)
    if (!success && connected) setConnected(false)
  }

  useInterval(checkConnectivity, 5000)

  const close = () => dispatch(setDoubleSidebarBottom({ isOpen: false, content: '' }))

  const onCardNumberElementReady = (e) => {
    cardNumberElementRef.current = e

    e.on('change', (event) => {
      console.log({ event })
      if (event.complete) {
        setCardNumberEntered(true)
        return
      }
      if (event.error) {
        Sentry.captureException(event.error)
      }
      setCardNumberEntered(false)
    })
  }
  const onCardCVCElementReady = (e) => {
    cardCVCElementRef.current = e

    e.on('change', (event) => {
      console.log({ event })
      if (event.complete) {
        setCardCVCEntered(true)
        return
      }
      if (event.error) {
        Sentry.captureException(event.error)
      }
      setCardCVCEntered(false)
    })
  }
  const onCardExpElementReady = (e) => {
    cardExpElementRef.current = e

    e.on('change', (event) => {
      console.log({ onCardExpElementReady: event })
      if (event.complete) {
        setCardExpEntered(true)
        return
      }
      if (event.error) {
        Sentry.captureException(event.error)
      }
      setCardExpEntered(false)
    })
  }

  const onCardElementReady = (e) => {
    e.on('change', (event) => {
      console.log({ event })
      if (event.complete) {
        setCardDetailsEntered(true)
        return
      }
      if (event.error) {
        Sentry.captureException(event.error)
      }
      setCardDetailsEntered(false)
    })
  }

  const dispatchPaymentMethod = () => {
    setFormErrors([])
    let cardElement = elements.getElement(CardNumberElement)
    dispatch({
      type: 'ADD_USER_PAYMENT_METHOD',
      payload: usePaymentMethods.addPaymentMethod({ stripe, userId, cardElement, postcode }),
    })
  }

  const showFormErrors = () => {
    let errors = []
    if (!cardCVCEntered) errors.push(createError(`CVC number is not valid`))
    if (!cardExpEntered) errors.push(createError(`Expiry date is not valid`))
    if (!cardNumberEntered) errors.push(createError(`Card number is not valid`))
    if (!postcodeValid) errors.push(createError(`Postcode is not valid`))

    setFormErrors(errors)
  }

  return (
    <FormContainer style={{ width: '100%' }}>
      <View row spaceBetween style={{ paddingTop: 10, alignItems: 'center' }}>
        <Text size={16} weight={600} style={{ paddingLeft: 20 }}>
          {t('Add card')}
        </Text>
        <ColoredButton onClick={close} size={45} color="rgba(0,0,0,0.1)">
          <FaArrowDown />
        </ColoredButton>
      </View>
      <Spacer height={20} />
      <View
        style={{
          flex: 1,
          background: 'white',
          borderRadius: 5,
          border: '1px solid rgba(0,0,0,0.1)',
          padding: 10,
          margin: 8,
          paddingInline: 15,
        }}
      >
        <CardNumberElement
          options={{ showIcon: true, style: { base: { fontSize: '18px' } } }}
          onReady={onCardNumberElementReady}
        />
      </View>
      <View row={isLandscape ? true : false}>
        <View row spaceBetween={isLandscape ? false : true}>
          <View
            style={{
              background: 'white',
              borderRadius: 5,
              border: '1px solid rgba(0,0,0,0.1)',
              paddingBlock: 13,
              paddingInline: 15,
              textAlign: 'center',
              width: isLandscape ? 100 : '45%',
              margin: 8,
            }}
            onClick={() => {
              cardExpElementRef?.current?.focus()
            }}
          >
            <CardExpiryElement
              options={{ style: { base: { fontSize: '18px' } } }}
              onReady={onCardExpElementReady}
            />
          </View>
          <View
            style={{
              background: 'white',
              margin: 8,
              borderRadius: 5,
              width: isLandscape ? 100 : '45%',
              height: 50,
              border: '1px solid rgba(0,0,0,0.1)',
              paddingBlock: 13,
              paddingInline: 15,
            }}
            onClick={() => {
              cardCVCElementRef?.current?.focus()
            }}
          >
            <CardCvcElement
              options={{ style: { base: { fontSize: '18px', padding: '10px' } } }}
              onReady={onCardCVCElementReady}
            />
          </View>
        </View>

        <View style={{ margin: 8, flex: 1 }}>
          <AnimatedTextInput
            height={50}
            inputStyle={{ paddingLeft: `17px`, paddingBottom: `10px` }}
            placeholderStyle={{ paddingTop: 13 }}
            value={postcode}
            setValue={(postcode) => {
              const postcodeWithoutWhitespace = removeWhiteSpace(postcode)
              if (postcodeValidator(postcodeWithoutWhitespace, 'INTL')) {
                setPostcodeValid(true)
              } else {
                if (postcodeWithoutWhitespace.length > 6) setPostcodeValid(false)
              }
              setPostcode(String(postcode).toUpperCase())
            }}
            placeholder="Postcode"
          />
        </View>
      </View>

      {!connected && (
        <>
          <Spacer height={20} />
          <Warning>You're offline, so cannot add your card.</Warning>
          <Spacer height={10} />
        </>
      )}

      {!!rejected && (
        <>
          <Spacer height={20} />
          {isError(error) && <Error>{error.message}</Error>}
          {!isError(error) && <Error>Sorry there was a problem, please try again later</Error>}
          <Spacer height={10} />
        </>
      )}
      {formErrors.length > 0 && (
        <>
          <Spacer height={20} />
          {map(
            (error) => (
              <Fragment key={error.message}>
                <Warning>{error.message}</Warning>
                <Spacer height={10} />
              </Fragment>
            ),
            formErrors
          )}

          <Spacer height={10} />
        </>
      )}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
        <ColoredButton
          size={45}
          auto
          disabled={!cardCVCEntered || !cardExpEntered || !cardNumberEntered || !postcodeValid || pending}
          onClick={dispatchPaymentMethod}
          disabledClick={showFormErrors}
        >
          <Text size={18} weight={400} style={{ paddingInline: 40 }}>
            {t('Add')}
          </Text>
        </ColoredButton>
      </div>
      <StripeSecureLogo />
      <Spacer height={250} />
    </FormContainer>
  )
}

export default AddPaymentMethodModal
