import { useStripe } from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'
import { PaymentMethod } from '@cnd/common/types'

import * as Sentry from '@sentry/nextjs'

interface UseApplePayProps {
  paymentTotal?: number
}

const createPaymentRequestOptions = ({ total: amount }) => ({
  country: 'GB',
  currency: 'gbp',
  total: { label: 'My Hiro', amount: parseInt(amount) },
  requestPayerEmail: false,
  requestShipping: false,
  requestPayerName: false,
  requestPayerPhone: false,
})

const useApplePayWeb = ({ paymentTotal = 0 }: UseApplePayProps = {}) => {
  const stripe = useStripe()
  const [canUseApplePay, setCanUseApplePay] = useState(false)
  const paymentRequestOptions = createPaymentRequestOptions({ total: paymentTotal })
  const [pending, setPending] = useState(true)

  const updateTotalInPaymentRequest = (total: number) => ({
    total: { label: 'Hiro', amount: total },
  })

  const checkIfCanUseApplePayWeb = async ({ stripe }) => {
    if (!stripe) return false

    const paymentRequest = stripe.paymentRequest(paymentRequestOptions)

    try {
      const result = await paymentRequest.canMakePayment()
      if (result && result.applePay) return true
    } catch (error) {
      Sentry.captureException(error)
    }
    return false
  }

  const checkIfCanUseApplePay = async ({ stripe }) => {
    setPending(true)
    let canUseApplePay = await checkIfCanUseApplePayWeb({ stripe })
    setCanUseApplePay(canUseApplePay)
    setPending(false)
  }

  useEffect(() => {
    checkIfCanUseApplePay({ stripe })
  }, [stripe])

  const applePaymentMethod: PaymentMethod = {
    id: 'apple-pay',
    key: 'apple-pay',
    brand: 'Apple Pay',
    object: 'applePay',
  }

  console.log({ canUseApplePay })

  return {
    pending,
    paymentRequestOptions,
    canUseApplePay,
    applePaymentMethod,
    updateTotalInPaymentRequest,
    createPaymentRequestOptions,
  }
}

export default useApplePayWeb
