import api from '@services/api'
import { locations } from './data'
import { getDataFromNFC } from '@services/nfc'

export const hours = [
  0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11,
]

export const calcCrow = ($lat1, $lon1, $lat2, $lon2) => {
  const $R = 6371 // km
  const $dLat = toRad($lat2 - $lat1)
  const $dLon = toRad($lon2 - $lon1)
  $lat1 = toRad($lat1)
  $lat2 = toRad($lat2)

  const $a =
    Math.sin($dLat / 2) * Math.sin($dLat / 2) +
    Math.sin($dLon / 2) * Math.sin($dLon / 2) * Math.cos($lat1) * Math.cos($lat2)
  const $c = 2 * Math.atan2(Math.sqrt($a), Math.sqrt(1 - $a))
  const $d = $R * $c
  return $d
}

export const toRad = ($Value) => ($Value * Math.PI) / 180

const allowedDistanceFromLocationsInKM = 500

export const isPositionCloseToLocation = ({ longitude, latitude }) => {
  const distanceFromShop = calcCrow(longitude, latitude, locations.shop.longitude, locations.shop.latitude)
  const distanceFromDK = calcCrow(longitude, latitude, locations.DK.longitude, locations.DK.latitude)

  if (
    distanceFromDK > allowedDistanceFromLocationsInKM &&
    distanceFromShop > allowedDistanceFromLocationsInKM
  )
    return false
  return true
}

export const getLocationCloseToPosition = ({ longitude, latitude }) => {
  const distanceFromShop = calcCrow(longitude, latitude, locations.shop.longitude, locations.shop.latitude)
  const distanceFromDK = calcCrow(longitude, latitude, locations.DK.longitude, locations.DK.latitude)

  if (distanceFromDK < allowedDistanceFromLocationsInKM) return locations.DK
  if (distanceFromShop < allowedDistanceFromLocationsInKM) return locations.shop
  return null
}

export const getLocationFromChip = async () => {
  let data = await getDataFromNFC()

  if (data.includes('shop')) return locations.shop
  if (data.includes('dk')) return locations.DK
}
