import { LoadingAnimation, Text, View } from '@cnd/elements'

import PayWithCard from './PayWithCard'
import PaymentSickyBottomButtonContainer from './PaymentSickyBottomButtonContainer'
import PayWithApplePayOnWeb from './PayWithApplePayOnWeb'
import PayWithGooglePayOnWeb from './PayWithGooglePayOnWeb'

import PayWithApplePayInApp from './PayWithApplePayInApp'
import PayWithGooglePayInApp from './PayWithGooglePayInApp'

import PayForFree from './PayForFree'
import usePayment from './usePayment'
import useBusinessHours from '@store/useBusinessHours'
import useOrder from '@store/useOrder'

import { isApp, isWebsite } from '@services/platform'
import useUser from '@store/useUser'
import { absoluteFillObject } from '@services/StyleSheet'
import Lottie from 'lottie-react'
import paying from '../../../public/paying.json'

import { priceToPounds } from '@cnd/common/functions/menu'

let usePaymentMethods
if (isApp) {
  usePaymentMethods = require('@store/usePaymentMethodsApp').default
} else {
  usePaymentMethods = require('@store/usePaymentMethodsWeb').default
}

const Payments = () => {
  const { isShopClosed } = useBusinessHours()
  const isPreOrder = useOrder('isPreOrder')
  const paymentPending = useOrder('paymentPending')
  const { isReadyToTakePayment } = usePayment()
  const totalIncludingDelivery = useOrder('totalIncludingDelivery')
  const pending = useUser('paymentMethodsPending')

  const { selectedPaymentMethod } = usePaymentMethods()
  const isFree = totalIncludingDelivery === 0

  if (isShopClosed && !isPreOrder) return null

  if (paymentPending)
    return (
      <View
        center
        motion
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{ ...absoluteFillObject, zIndex: 100, background: 'rgba(0,0,0,0.8)' }}
      >
        <View style={{ position: 'relative' }}>
          <View
            center
            style={{ position: 'absolute', top: 10, zIndex: 101, left: 0, right: 0 }}
            motion
            initial={{ scale: 0.1 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.1 }}
          >
            <Text size={20} color="black" style={{ marginTop: 25 }} weight={600}>
              Paying
            </Text>
          </View>
          <View style={{ borderRadius: 35, background: 'white', width: 250, height: 250 }} center>
            <Lottie animationData={paying} style={{ width: 250, borderRadius: 30 }} />
          </View>
        </View>
      </View>
    )

  if (isFree)
    return (
      <PaymentSickyBottomButtonContainer>
        {pending ? (
          <View center style={{ width: '100%', padding: 15 }}>
            <LoadingAnimation style={{ height: 15, width: 3, radius: 4 }} />
          </View>
        ) : (
          <PayForFree />
        )}
      </PaymentSickyBottomButtonContainer>
    )

  if (!isReadyToTakePayment) return <View />
  if (!selectedPaymentMethod) return <View />

  return (
    <PaymentSickyBottomButtonContainer>
      <View row spaceBetween pad={3}>
        <Text size={15}>Total</Text>
        <Text size={15} weight={500}>
          £{priceToPounds(totalIncludingDelivery)}
        </Text>
      </View>
      {pending && (
        <View center style={{ width: '100%', padding: 15 }}>
          <LoadingAnimation style={{ height: 15, width: 3, radius: 4 }} />
        </View>
      )}
      {!pending && (
        <>
          {isWebsite && selectedPaymentMethod.object === 'applePay' && <PayWithApplePayOnWeb />}
          {isWebsite && selectedPaymentMethod.object === 'googlePay' && <PayWithGooglePayOnWeb />}

          {isApp && selectedPaymentMethod.object === 'applePay' && <PayWithApplePayInApp />}
          {isApp && selectedPaymentMethod.object === 'googlePay' && <PayWithGooglePayInApp />}

          {selectedPaymentMethod.object === 'card' && <PayWithCard paymentMethod={selectedPaymentMethod} />}
        </>
      )}
    </PaymentSickyBottomButtonContainer>
  )
}

export default Payments
