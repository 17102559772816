import { Button, Loading, ScrollView, Text, View, Spacer } from '@cnd/elements'
import PlaceOrderForm from '@components/CheckoutSteps/PlaceOrderForm/index'

import Payments from '@components/CheckoutSteps/PlaceOrderForm/Payments'
import { useEffectOnce } from 'usehooks-ts'
import useOrder from '@store/useOrder'
import useInsights from '@hooks/useInsights'
import useBusinessHours from '@store/useBusinessHours'
import { useTypedDispatch } from '@store'
import CheckoutHeader from './CheckoutHeader'
import colors from '@constants/colors'
import { FaArrowLeftLong } from 'react-icons/fa6'
import { useTranslation } from 'react-i18next'
import { isApp } from '@services/platform'

let LoadStripe

if (isApp) {
  LoadStripe = require('../LoadStripeApp').default
} else {
  LoadStripe = require('../LoadStripeWeb').default
}

const PlaceOrder = ({ back }) => {
  const { t } = useTranslation()
  const { log } = useInsights()
  const basketTotal = useOrder('total')
  const dispatch = useTypedDispatch()

  const { isShopOpen } = useBusinessHours()

  useEffectOnce(() => {
    log(`Placing Order`, { basketTotal })
    dispatch({ type: 'CLEAR_PAYMENT_ERROR' })
  })

  return (
    <>
      <CheckoutHeader
        title={t(`Checkout`)}
        left={
          <View style={{ padding: 10, width: 50 }} onClick={back} motion whileTap={{ scale: 0.8 }}>
            <FaArrowLeftLong color={colors.nBlack()} size={14} />
          </View>
        }
      />
      <LoadStripe>
        <ScrollView absolute={false} style={{ height: '100vh' }}>
          {isShopOpen === null && (
            <View center>
              <View
                style={{
                  maxWidth: 280,
                  background: 'white',
                  borderRadius: 10,
                  padding: 10,
                  borderWidth: 1,
                  borderColor: 'rgba(0,0,0,0.07)',
                  borderStyle: 'solid',
                  marginTop: 30,
                }}
              >
                <Text center style={{ marginBottom: 20 }}>
                  We may not be open or you may not have selected your delivery location.
                </Text>
                <View center>
                  <Text center size={12} style={{ maxWidth: 180 }}>
                    Please go back to the menu and start your order.
                  </Text>
                  <Spacer height={10} />
                  <Button small onClick={back} style={{ width: 100 }}>
                    Back
                  </Button>
                </View>
              </View>
            </View>
          )}
          {isShopOpen !== null && <PlaceOrderForm />}
        </ScrollView>
        <Payments />
      </LoadStripe>
    </>
  )
}

export default PlaceOrder
