import { Button, Text } from '@cnd/elements'
import { useTranslation } from 'react-i18next'

const PayButton = ({ label = 'Pay with card', isPaying, formComplete, amount, pay }) => {
  const { t } = useTranslation()
  return (
    <Button style={{ width: '100%' }} disabled={!formComplete || isPaying} onClick={pay}>
      <Text weight={500} size={17} color="white" style={{ padding: 3 }}>
        {isPaying ? t('PLACING ORDER') : t(label) + ' £' + amount}
      </Text>
    </Button>
  )
}
export default PayButton
