import Router from 'next/router'

import { Spacer, View, ScrollView, Text, Button } from '@cnd/elements'

import { priceToPounds } from '@cnd/common/functions/menu'

import map from 'lodash/map'
import useUser from '@store/useUser'

import { filter, sort, values } from 'ramda'
import api from '@services/api'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getTime } from 'date-fns'
import { useSafeAreaInsets } from '@components/SafeAreaView'
import { FaArrowRightLong } from 'react-icons/fa6'
import colors from '@constants/colors'
import ColoredCard from '@components/ColoredCard'

const OrderStatus = ({ status }) => {
  let niceStatus = null
  if (status === 'out_for_delivery') niceStatus = 'Out for delivery'
  if (status === 'paid') niceStatus = 'Paid'
  if (status === 'delivered') niceStatus = 'Delivered'
  if (status === 'cooking') niceStatus = 'Cooking'
  return (
    <View>
      <Text color={'grey'} size={12}>
        {niceStatus}
      </Text>
    </View>
  )
}

const dateDifference = (type: 'earliest_first' | 'latest_first') => (a, b) =>
  type === 'earliest_first'
    ? getTime(new Date(a.cardChargedAtTime)) - getTime(new Date(b.cardChargedAtTime))
    : getTime(new Date(b.cardChargedAtTime)) - getTime(new Date(a.cardChargedAtTime))

const OrdersList = () => {
  const { t } = useTranslation()
  const orders = useUser('orders')
  const insets = useSafeAreaInsets()
  const [runningOnDev, setRunningOnDev] = useState(api.defaults.baseURL !== 'https://api.myhiro.club/api')

  const ordersSorted = sort(
    dateDifference('latest_first'),
    filter(({ id }) => !!id, values(orders))
  )
  const numberOfOrders = map(orders).length

  const switchApi = () => {
    console.log({ baseURL: api.defaults.baseURL })
    if (runningOnDev) {
      api.defaults.baseURL = 'https://api.myhiro.club/api'
    }
    if (!runningOnDev) {
      api.defaults.baseURL = 'https://myhiroapi.ngrok.io/api'
    }

    setRunningOnDev(api.defaults.baseURL !== 'https://api.myhiro.club/api')
  }

  const goToMenu = () => {
    Router.push('/Menu')
  }

  const goToOrder = (orderId) => {
    Router.push(`/MyOrder/[orderId]`, `/MyOrder/${orderId}`)
  }

  return (
    <>
      <View style={{ paddingLeft: 10, paddingBottom: 30, paddingTop: 15 }}>
        <Text size={16} weight={500}>
          Completed <Text block={false}>({numberOfOrders})</Text>
        </Text>
      </View>
      <ScrollView absolute={false} style={{ height: '100vh' }}>
        {numberOfOrders === 0 && (
          <View center style={{ paddingTop: 10 }}>
            <Text center size={20} weight={600}>
              {t('No orders')}
            </Text>
            <Spacer height={20} />
            <Text center style={{ width: 220 }}>
              {t('Check out the menu to create your first order.')}
            </Text>

            <Button style={{ maxWidth: '150px', margin: '25px auto 0 auto' }} onClick={goToMenu}>
              {t('Menu')}
            </Button>
          </View>
        )}
        {numberOfOrders > 0 &&
          map(ordersSorted, (order) => {
            if (!order.id) return null
            return (
              <ColoredCard
                color={'white'}
                center
                spaceBetween
                row
                key={order.id}
                style={{ padding: 15 }}
                onClick={() => goToOrder(order.id)}
              >
                <View>
                  <View>
                    <OrderStatus status={order.status} />
                    <Text size={16}>{new Date(order.cardChargedAtTime).toDateString()}</Text>
                    <Spacer height={5} />
                    <Text size={14}>{`£${priceToPounds(order.totalIncludingDelivery)}`}</Text>
                  </View>
                  <View>
                    <Text color="rgba(0,0,0,0.2)" size={11}>
                      {t('ref')} {order.id.substring(0, 8)}
                    </Text>
                  </View>
                </View>
                <FaArrowRightLong size={18} color={colors.nBlack()} />
              </ColoredCard>
            )
          })}
      </ScrollView>
    </>
  )
}
export default OrdersList
