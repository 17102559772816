import { View } from '@cnd/elements'
import { useSafeAreaInsets } from '@components/SafeAreaView'
import AccountMenuLinks from '@components/AccountMenu/AccountMenuLinks'
import SidebarHeader from '@components/AccountMenu/SidebarHeader'
import { useTypedDispatch } from '@store'
import { closeSidebarBottom } from '@store/actions'
import { useState } from 'react'
import AccountDietaryRequirements from './AccountDietaryRequirements'
import AccountMenuMyOrders from './AccountMenuMyOrders'

const PAGES = {
  ACCOUNT_MENU: {
    key: 'ACCOUNT_MENU',
    name: `Account`,
    headerLeft: 'CLOSE',
  },
  DIETARY_REQUIREMENTS: {
    key: 'DIETARY_REQUIREMENTS',
    name: `Dietary Requirements`,
    headerLeft: 'BACK',
  },
  MY_ORDERS: {
    key: 'MY_ORDERS',
    name: `My Orders`,
    headerLeft: 'BACK',
  },
}

const AccountMenu = () => {
  const insets = useSafeAreaInsets()
  const dispatch = useTypedDispatch()
  const close = () => {
    dispatch(closeSidebarBottom())
  }

  const [page, setPage] = useState('ACCOUNT_MENU')

  const pageMeta = PAGES[page]

  return (
    <View>
      <SidebarHeader page={pageMeta} close={close} back={() => setPage('ACCOUNT_MENU')} />
      <View
        center
        style={{
          padding: '25px 10px 15px 10px',
          position: 'relative',
          maxWidth: 550,
          paddingBottom: insets.bottom + 25,
          paddingTop: 70,
          margin: '0 auto',
          width: '100%',
        }}
      >
        {page === 'ACCOUNT_MENU' && <AccountMenuLinks setAccountPage={setPage} />}
        {page === 'MY_ORDERS' && <AccountMenuMyOrders />}
        {page === 'DIETARY_REQUIREMENTS' && <AccountDietaryRequirements />}
      </View>
    </View>
  )
}

export default AccountMenu
