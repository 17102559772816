import { useSafeAreaInsets } from '@components/SafeAreaView'
import { SHADOW_UP } from '@constants/styles'
import { View } from '@cnd/elements'

const PaymentSickyBottomButtonContainer = ({ children }) => {
  const insets = useSafeAreaInsets()
  return (
    <View
      center
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 20,
        paddingTop: 10,
        background: 'white',
        borderTop: `1px solid rgba(0,0,0,0.1)`,
        zIndex: 10,
        paddingBottom: insets.bottom + 10,
      }}
    >
      <View style={{ width: '100%', maxWidth: 580 }}>{children}</View>
    </View>
  )
}
export default PaymentSickyBottomButtonContainer
