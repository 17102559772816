import { memo, useState } from 'react'
import { Spacer, Text, View } from '@cnd/elements'
import { useTypedDispatch, useTypedSelector } from '@store'
import api from '@services/api'
import BackButton from '../BackButton'
import { closeDoubleSidebarBottom, closeSidebarBottom } from '@store/actions'
import { isValidPhoneNumber } from 'react-phone-number-input'
import * as Sentry from '@sentry/nextjs'
import LoginPhoneInput from './LoginPhoneInput'
import LoginPhoneContinueButton from './LoginPhoneContinueButton'
import ColoredButton from '@components/ColoredButton'
import { FaArrowDown } from 'react-icons/fa6'

const SaveContactPhone = () => {
  const dispatch = useTypedDispatch()
  const tempPhoneNumber = useTypedSelector((state) => state.user.tempPhoneNumber)
  const userId = useTypedSelector((state) => state.user.userId)
  const [phoneNumber, setPhoneNumber] = useState(tempPhoneNumber || '')

  const [loading, setLoading] = useState(false)
  const [signInError, setSignInError] = useState('')

  const sendPhoneNumber = async () => {
    try {
      setSignInError('')
      setLoading(true)

      dispatch({ type: 'SET_TEMP_PHONE_NUMBER', payload: phoneNumber })

      const { data } = await api.post(`/issue_verification_code`, { phoneNumber, userId })
      if (data.existingUserFound) dispatch({ type: 'SET_AUTH_SWITCH', payload: true })
      dispatch({ type: 'SET_AUTH_STATUS', payload: 'requesting-code' })
    } catch (error) {
      Sentry.captureException(error)
      setSignInError('There was a problem signing you in, please try again later')
    }
    setLoading(false)
  }

  const setPhoneNumberANDResetError = (value: string) => {
    if (!!signInError) setSignInError('')
    setPhoneNumber(!!value ? value : '')
  }

  const onGoBack = () => {
    dispatch(closeDoubleSidebarBottom())
  }

  return (
    <div style={{ height: '100%', paddingTop: 20 }}>
      <View style={{ paddingLeft: 25, position: 'absolute', right: 20 }}>
        <ColoredButton onClick={onGoBack} size={45}>
          <FaArrowDown />
        </ColoredButton>
      </View>
      <Spacer height={70} />
      <View center>
        <Text center size={24} style={{ lineHeight: '36px' }}>
          Your number
        </Text>

        <Spacer height={20} />
        <LoginPhoneInput phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumberANDResetError} />

        <Spacer height={20} />

        <LoginPhoneContinueButton
          error={signInError}
          loading={loading}
          disabled={!isValidPhoneNumber(phoneNumber)}
          onClick={sendPhoneNumber}
        />
      </View>
      <Spacer height={355} />
    </div>
  )
}
export default memo(SaveContactPhone)
