import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faMinus } from '@fortawesome/free-solid-svg-icons'
import { View } from '@cnd/elements'

const GoogleMapZoomButtons = ({ size = 28, zoom, setZoom }) => {
  return (
    <View
      style={{
        position: 'absolute',
        zIndex: 10,
        border: '1px solid rgba(0, 0, 0, 0.1)',
        background: 'white',
        borderRadius: 5,
        bottom: '35%',
        right: 10,
        boxShadow: '0px 0px 10px -6px',
        transform: 'translate(0, 50%)',
      }}
    >
      <FontAwesomeIcon
        className={'button'}
        icon={faAdd}
        fontSize={size}
        onClick={() => setZoom(zoom + 1)}
        style={{ padding: 10 }}
      />
      <FontAwesomeIcon
        className={'button bottom-button'}
        icon={faMinus}
        fontSize={size}
        onClick={() => setZoom(zoom - 1)}
        style={{ padding: 10 }}
      />
    </View>
  )
}

export default GoogleMapZoomButtons
