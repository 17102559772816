import { Button, Error, Text } from '@cnd/elements'
import ColoredButton from '@components/ColoredButton'
import { memo } from 'react'

const LoginPhoneContinueButton = ({ error, loading, disabled, onClick }) => {
  return (
    <>
      {!!error && <Error style={{ maxWidth: 250 }}>{error}</Error>}
      {!loading && (
        <ColoredButton onClick={onClick} disabled={disabled} auto size={50}>
          <Text weight={500} style={{ paddingInline: 18 }} size={18}>
            Continue
          </Text>
        </ColoredButton>
      )}
      {loading && (
        <ColoredButton onClick={onClick} disabled={true} auto size={50}>
          <Text weight={500} style={{ paddingInline: 18 }} size={18}>
            Sending Code
          </Text>
        </ColoredButton>
      )}
    </>
  )
}

export default memo(LoginPhoneContinueButton)
