import React, { useState, useEffect } from 'react'

import GoogleMapZoomButtons from './GoogleMapZoomButtons'

import GoogleMapPin from '@components/GoogleMapPin'
import GoogleMapPinDriver from '@components/GoogleMapPinDriver'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'

import api from '@services/api'
import { View } from '@cnd/elements'
import * as Sentry from '@sentry/nextjs'

const googleDirectionsApi = async (originCoords, destinationCoords) => {
  try {
    const { data } = await api.post(`/getDirections`, {
      originCoords: originCoords,
      destinationCoords: destinationCoords,
    })
    return data
  } catch (e) {
    Sentry.captureException(e)
    return false
  }
}

interface UserLocationProps {
  center: { lat: number; lng: number }
  location: { latLng: { lat: number; lng: number } }
  ourLocation: { latLng: { lat: number; lng: number } }
  height: string
  width: string
  driver?: null | any
}

const UserLocation = ({ center, location, ourLocation, height, width, driver = null }: UserLocationProps) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAP_API_KEY,
  })

  const { lat, lng } = center

  const defaultZoom = 16

  const [zoom, setZoom] = useState(defaultZoom)
  const [map, setMap] = useState(false)

  useEffect(() => {
    if (map && ourLocation && location) {
      callGoogleDirectionsApi(ourLocation.latLng, location.latLng)
    }
  }, [map, ourLocation, location])

  const callGoogleDirectionsApi = async (originCoords, destinationCoords) => {
    const directions = await googleDirectionsApi(
      { lng: originCoords.lng, lat: originCoords.lat },
      { lat: destinationCoords.lat, lng: destinationCoords.lng }
    )

    if (directions && directions.polylinePoints) {
      // const latLngArr = directions.polylinePoints.map((verboseLatLng) => {
      //   return new maps.LatLng(verboseLatLng.latitude, verboseLatLng.longitude)
      // })
      // if (maps) {
      //   const deliveryPath = new maps.Polyline({
      //     path: latLngArr,
      //     geodesic: true,
      //     strokeColor: '#FF0000',
      //     strokeOpacity: 1.0,
      //     strokeWeight: 2,
      //   })
      //   deliveryPath.setMap(map)
      // }
    }
  }

  const apiIsLoaded = (map) => {
    setMap(map)
  }

  if (!isLoaded) return null

  return (
    <View>
      <div style={{ height, width, position: 'relative' }}>
        <GoogleMapZoomButtons zoom={zoom} setZoom={setZoom} />
        <GoogleMap
          mapContainerStyle={{ height: '100%', width: '100%' }}
          center={{ lat, lng }}
          zoom={zoom}
          onLoad={apiIsLoaded}
          options={{
            disableDefaultUI: true,
            zoomControl: false,
            scrollwheel: false,
          }}
        >
          {ourLocation && <Marker position={{ lat, lng }} />}
          <GoogleMapPin icon="/MapPin.svg" />
          {driver && driver.driverImageThumbnail && (
            <GoogleMapPinDriver driverImage={driver.driverImageThumbnail} />
          )}
        </GoogleMap>
      </div>
    </View>
  )
}

export default UserLocation
