import colors from '@constants/colors'
import { CSSProperties } from 'react'

const cardStyle: CSSProperties = {
  padding: 10,
  borderRadius: 10,
  margin: 10,
  border: `1px solid ${colors.nBlack(0.2)}`,
  background: 'white',
  zIndex: 1,
  position: 'relative',
}

export default cardStyle
