import { memo, useState } from 'react'

import { Error, Spacer, NumericCodeInput, View, B, Text, Button } from '@cnd/elements'
import { useTypedDispatch, useTypedSelector } from '@store'
import api from '@services/api'
import useUser from '@store/useUser'

import { loginAction } from '@store/userMiddleware'

const LoginFormConfirm = ({ next }) => {
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState('')

  const loading = useTypedSelector((state) => state.user.pending)

  const userId = useUser('userId')
  const dispatch = useTypedDispatch()
  const tempPhoneNumber = useTypedSelector((state) => state.user.tempPhoneNumber)
  const [loadingState, setLoadingState] = useState('')

  const setCodeFromUserInput = (userCode) => {
    setCodeError('')
    setCode(userCode)
  }

  const tapToResend = async () => {
    try {
      setLoadingState('SENDING')

      await api.post(`/issue_verification_code`, { phoneNumber: tempPhoneNumber, userId })
    } catch (error) {}
    setLoadingState('')
  }

  const confirm = async () => {
    try {
      setLoadingState('CHECKING')
      setCodeError('')

      const { data } = await api.post(`/verify_verification_code`, {
        phoneNumber: tempPhoneNumber,
        code,
        userId,
      })

      const { accessToken, userId: localUserId } = data
      await dispatch(loginAction({ accessToken, userId: localUserId }))
      next()
    } catch (error) {
      console.warn({ confirm: error })
      if (error.name === 'AxiosError' && error?.response?.data?.error === 'Code has expired')
        setCodeError('Your code has expired, please tap below to resend a new one')
      else if (error.name === 'AxiosError' && error?.response?.data?.error === 'Code is incorrect')
        setCodeError('The code you entered is incorrect, please check and try again')
      else setCodeError('There was a problem validating your phone number, please try again later')
    }
    setLoadingState('')
  }

  const goBack = () => {
    dispatch({ type: 'SET_AUTH_STATUS', payload: 'requesting-number' })
  }

  const confirmDisabled = code.length !== 6 || !!loadingState ? true : false

  console.log({ loading, loadingState })

  return (
    <>
      <Text style={{ maxWidth: 260 }}>
        Enter the code we sent in a text message to <B>{tempPhoneNumber}</B>
      </Text>
      <Spacer height={35} />

      <NumericCodeInput onChange={setCodeFromUserInput} onPresEnterKey={confirm} />
      <Spacer height={35} />

      {!!codeError && <Error>{codeError}</Error>}
      <Button onClick={confirm} disabled={confirmDisabled}>
        {loading || loadingState !== '' ? loadingState : 'Confirm'}
      </Button>
      <Spacer height={35} />
      <View onClick={tapToResend} style={{ cursor: 'pointer' }}>
        <Text size={14}>
          Didn't get a text?
          <Text style={{ color: 'darkred', textDecoration: 'underline' }}>Tap to resend.</Text>
        </Text>
      </View>

      <Spacer height={35} />
      <View onClick={goBack} style={{ cursor: 'pointer', paddingBlock: 5 }}>
        <Text size={14}>
          <Text style={{ textDecoration: 'underline' }}>back</Text>
        </Text>
      </View>
      <Spacer height={255} />
    </>
  )
}

export default memo(LoginFormConfirm)
