import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import SidebarItemContainer from './SidebarItemContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { View } from '@cnd/elements'

interface MenuItemProps {
  noTopBorder?: boolean
  route?: string
  icon?: IconDefinition
  children?: any
  onClick?: any
  name?: string
  variants?: any
}

const MenuItemLink = ({ route, icon, children = null, onClick, name = null }: MenuItemProps) => {
  const goTo = () => {
    if (!route) return
    setTimeout(() => {
      window.open(route, '_blank').focus()
    }, 400)
  }
  return (
    <SidebarItemContainer motion whileTap={{ scale: 0.9 }} onClick={onClick || goTo}>
      <View row center>
        {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: 10 }} />}
        {!!name ? name : children}
      </View>
    </SidebarItemContainer>
  )
}
export default MenuItemLink
