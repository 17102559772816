import { useEffect, useRef, useState } from 'react'

import {
  View,
  Spacer,
  AnimatedTextAreaInput,
  Text,
  GreenButton,
  AnimatedTextInput,
  Error,
  Switch,
} from '@cnd/elements'
import { setDoubleSidebarBottomIsOpen, setSidebarBottomIsOpen } from '@store/actions'

import { useTypedDispatch, useTypedSelector } from '@store'
import { ErrorType, createError } from '@cnd/redash'
import api from '@services/api'
import { useSafeAreaInsets } from '@components/SafeAreaView'
import { DeliveryLocationKeys } from '@store/initialState'
import useUpdateUser from '@hooks/useUpdateUser'
import { useToggle } from 'usehooks-ts'
import { isValidDeliveryLocation } from '@cnd/common/functions/payment'

const AddDeliveryLocation = () => {
  const dispatch = useTypedDispatch()
  const updateUser = useUpdateUser()

  const [saveAsDefaultAddress, toggleSaveAsDefaultAddress] = useToggle(true)
  const isOpen = useTypedSelector((state) => state.doubleSidebarBottom.isOpen)

  const deliveryLocation = useTypedSelector((state) => state.user.order.deliveryLocation)

  const {
    pending,
    postcode = '',
    streetAddress1 = '',
    streetAddress2 = '',
    notes = '',
    city = 'London',
  } = deliveryLocation || {}

  const insets = useSafeAreaInsets()

  const [addressError, setAddressError] = useState<ErrorType | null>()

  const setAddressProp = (prop: DeliveryLocationKeys) => (value) =>
    dispatch({ type: 'UPDATE_ORDER_DELIVERY_LOCATION', payload: { [prop]: value } })

  useEffect(() => {
    setAddressProp('city')('London')
  }, [])

  const streetAddress2Ref = useRef(null)

  const close = () => dispatch(setDoubleSidebarBottomIsOpen(false))

  const saveAddress = async () => {
    await dispatch({
      type: 'UPDATE_ORDER_DELIVERY_LOCATION',
      payload: new Promise(async (resolve, reject) => {
        try {
          const { data: latLng } = await api.post('/GetLatLng', { postcode })

          const deliveryLocation = {
            streetAddress1,
            streetAddress2,
            postcode,
            city,
            notes,
            latLng,
            saved: saveAsDefaultAddress,
          }

          if (saveAsDefaultAddress) await updateUser({ defaultDeliveryAddress: deliveryLocation })

          resolve(deliveryLocation)
        } catch (error) {
          reject()
        }
      }),
    })

    close()
  }

  useEffect(() => {
    if (isOpen) setAddressError(null)
  }, [isOpen])

  const onKeyUpStreetAddress1 = (e) => {
    if (e.keyCode === 13) {
      streetAddress2Ref.current.focus()
    }
  }

  return (
    <View
      style={{
        backgroundColor: 'white',
        maxWidth: 550,
        width: '100%',
        margin: '0 auto',
        paddingBottom: insets.bottom + 25,
        position: 'relative',
      }}
    >
      <View
        center
        style={{
          position: 'fixed',
          left: 0,
          right: 0,
          background: 'white',
          zIndex: 50,
          border: '1px solid rgba(0,0,0,0.05)',
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
        }}
      >
        <View spaceBetween center row style={{ width: '100%', maxWidth: 550 }}>
          <Text bold size={20} style={{ margin: 20, display: 'block' }}>
            Delivery address
          </Text>

          <GreenButton
            style={{ marginRight: 15 }}
            disabledClick={() =>
              setAddressError(createError(`please make sure you've filled in all your address`))
            }
            disabled={!isValidDeliveryLocation(deliveryLocation) || pending}
            onClick={saveAddress}
          >
            {pending ? `Saving` : `Save`}
          </GreenButton>
        </View>
      </View>

      {addressError && <Error>{addressError?.message}</Error>}

      <View style={{ padding: 10 }}>
        <Spacer height={60} />
        <View spaceBetween row center style={{ padding: 10 }}>
          <Text size={16} weight={400}>
            Save as primary
          </Text>
          <Switch toggle={toggleSaveAsDefaultAddress} enabled={saveAsDefaultAddress} />
        </View>
        <Spacer height={10} />
        <AnimatedTextInput
          //onKeyUp={onKeyUpStreetAddress1}
          value={streetAddress1}
          setValue={setAddressProp('streetAddress1')}
          placeholder={'Address line 1'}
        />
        <Spacer height={15} />
        <AnimatedTextInput
          //ref={streetAddress2Ref}
          value={streetAddress2}
          setValue={setAddressProp('streetAddress2')}
          placeholder={'Address line 2'}
        />
        <Spacer height={15} />
        <AnimatedTextInput value={city} setValue={setAddressProp('city')} disabled />
        <Spacer height={15} />
        <AnimatedTextInput
          //ref={streetAddressPostcodeRef}
          inputStyle={{ textTransform: 'uppercase' }}
          value={postcode}
          setValue={setAddressProp('postcode')}
          placeholder={'Postcode'}
        />
        <Spacer height={15} />
        <AnimatedTextAreaInput
          //ref={driverInstructionsRef}
          value={notes}
          setValue={setAddressProp('notes')}
          placeholder={'Instructions for driver'}
        />
      </View>

      <Spacer height={355} />
    </View>
  )
}
export default AddDeliveryLocation
