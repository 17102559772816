import { Spacer, View } from '@cnd/elements'

import Image from 'next/image'

const IMAGE_SIZE = 0.8

const StripeSecureLogo = () => {
  return (
    <View center style={{ opacity: 0.92 }}>
      <Spacer height={35} />
      <Image
        src={'/stripe-secure.webp'}
        width={124 * IMAGE_SIZE}
        height={68 * IMAGE_SIZE}
        alt="Secured by Stripe logo"
      />
      <Spacer height={35} />
    </View>
  )
}
export default StripeSecureLogo
