import Router from 'next/router'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toggleSidebarMenu } from '@store/actions'
import { useTypedDispatch } from '@store'
import SidebarItemContainer from './SidebarItemContainer'
import { Text, View } from '@cnd/elements'

interface MenuItemProps {
  noTopBorder?: boolean
  route?: string
  icon?: IconDefinition
  children?: any
  onClick?: any
  name?: string
  variants?: any
  last?: boolean
  notification?: string
}

const MenuItem = ({ route, icon, children = null, onClick, name = null, notification }: MenuItemProps) => {
  const dispatch = useTypedDispatch()
  const goTo = () => {
    if (!route) return

    dispatch(toggleSidebarMenu())
    setTimeout(() => {
      Router.push(route)
    }, 400)
  }
  return (
    <SidebarItemContainer motion onClick={onClick || goTo} whileTap={{ scale: 0.9 }}>
      <View row center>
        {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: 10 }} />}
        {!!name ? name : children}
      </View>
      {!!notification && (
        <View row alignCenter style={{ position: 'relative' }}>
          <Text style={{ marginRight: 10 }}>{notification}</Text>
          <View
            style={{
              borderRadius: 10,
              background: '#ea4444',
              width: 14,
              height: 14,
              border: `2px solid white`,
            }}
          />
        </View>
      )}
    </SidebarItemContainer>
  )
}

export default MenuItem
