import { FaArrowLeftLong, FaArrowDownLong } from 'react-icons/fa6'
import colors from '@constants/colors'
import { AnimatePresence, Text, View } from '@cnd/elements'

const SidebarHeader = ({ close, page, back }) => {
  return (
    <AnimatePresence>
      <View
        center
        style={{
          borderBottom: `1px solid rgba(0,0,0,0.06)`,
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          zIndex: 10,
          background: 'white',
        }}
        key={page.key}
        motion
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
      >
        <View row spaceBetween pad={5} style={{ width: '100%', maxWidth: 500 }}>
          {page.headerLeft === 'CLOSE' && (
            <View
              motion
              style={{ width: 50, height: 'auto', padding: 10, position: 'relative', top: 4 }}
              onClick={close}
              whileTap={{ scale: 0.9 }}
            >
              <FaArrowDownLong size={18} color={colors.nBlack()} />
            </View>
          )}
          {page.headerLeft === 'BACK' && (
            <View
              motion
              style={{ width: 50, height: 'auto', padding: 10, position: 'relative', top: 4 }}
              onClick={back}
              whileTap={{ scale: 0.9 }}
            >
              <FaArrowLeftLong size={18} color={colors.nBlack()} />
            </View>
          )}
          <Text size={18} weight={500} style={{ padding: 10 }}>
            {page.name}
          </Text>
          <View style={{ width: 50 }} />
        </View>
      </View>
    </AnimatePresence>
  )
}

export default SidebarHeader
