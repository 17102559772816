import { memo } from 'react'
import LoginFormAddNumber from './LoginFormAddNumber'
import LoginFormConfirm from './LoginFormConfirm'

import { useTypedSelector } from '@store'

const LoginForm = ({ next }) => {
  const authStatus = useTypedSelector((state) => state.auth.authStatus)

  if (authStatus === 'requesting-number') return <LoginFormAddNumber />
  if (authStatus === 'requesting-code') return <LoginFormConfirm next={next} />
  return null
}

export default memo(LoginForm)
