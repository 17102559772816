import { View, Spacer, Text } from '@cnd/elements'
import { setSidebarBottomIsOpen } from '@store/actions'
import { useTypedDispatch, useTypedSelector } from '@store'
import { useSafeAreaInsets } from '@components/SafeAreaView'
import { map } from 'ramda'
import useClosestLocation from '@store/useClosestLocation'
import { useTranslation } from 'react-i18next'

const SelectDate = ({ pickDay, day: { dayName, isoDate, daysInTheFuture, timeSlots } }) => {
  const { t } = useTranslation()
  if (timeSlots.length === 0) return null
  return (
    <View
      motion
      onClick={() => pickDay(isoDate)}
      row
      style={{ padding: 15, background: 'rgba(0,0,0,0.07)', marginBottom: 10, borderRadius: 10 }}
      whileTap={{ opacity: 0.7, scale: 0.99 }}
      spaceBetween
    >
      <Text bold color="black">
        {t(dayName)}
      </Text>
      {daysInTheFuture === 1 && (
        <View row>
          <Text color="black" bold>
            {t('tomorrow')}
          </Text>
        </View>
      )}
      {daysInTheFuture === 0 && (
        <View row>
          <Text color="black" bold>
            {t('today')}
          </Text>
        </View>
      )}
      {daysInTheFuture > 1 && (
        <View row>
          <Text color="black">{t('in')}</Text>{' '}
          <Text color="black" weight={600} style={{ marginInline: 5 }}>
            {daysInTheFuture}
          </Text>{' '}
          <Text color="black">{t('days')}</Text>
        </View>
      )}
    </View>
  )
}

const renderDay = (pickDay) => (day) => <SelectDate key={day.isoDate} {...{ pickDay, day }} />

const EditNewUserDeliveryDate = () => {
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()
  const insets = useSafeAreaInsets()
  const isFor = useTypedSelector((s) => s.sidebarBottom.data?.isFor)
  const { preorderDeliveryDays } = useClosestLocation()

  const close = () => dispatch(setSidebarBottomIsOpen(false))

  const pickDay = (date) => {
    if (isFor === 'locations') {
      dispatch({ type: 'SET_LOCATION_SEARCH_DEL_TIME_FROM', payload: date })
    } else {
      dispatch({ type: 'UPDATE_ORDER', payload: { expectedDeliveryTimeFrom: date } })
    }
    close()
  }
  return (
    <View
      style={{
        backgroundColor: 'white',
        maxWidth: 550,
        width: '100%',
        margin: '0 auto',
        paddingBottom: insets.bottom + 25,
        position: 'relative',
      }}
    >
      <View
        spaceBetween
        center
        row
        style={{
          position: 'fixed',
          left: 0,
          right: 0,
          background: 'white',
          zIndex: 50,
          border: '1px solid rgba(0,0,0,0.05)',
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
        }}
      >
        <Text bold size={20} style={{ margin: 20, display: 'block' }}>
          {t('Delivery Date')}
        </Text>
      </View>

      <View style={{ padding: 10 }}>
        <Spacer height={70} />
        {map(renderDay(pickDay), preorderDeliveryDays)}
      </View>

      <Spacer height={355} />
    </View>
  )
}
export default EditNewUserDeliveryDate
