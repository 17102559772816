import { View } from '@cnd/elements'
import Image from 'next/image'
import React from 'react'

const GoogleMapPin = ({ driverImage }) => {
  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          height: 40,
          position: 'absolute',
          top: '-45px',
          left: '-15px',
        }}
      >
        <View
          style={{
            position: 'absolute',
            width: 0,
            height: 0,
            top: 23,
            left: 3.5,
            border: '12px solid transparent',
            borderTop: '20px solid #000',
            zIndex: 1,
          }}
        />
        <Image
          width={30}
          height={30}
          alt={'google map pin driver'}
          style={{
            position: 'absolute',
            zIndex: 2,
            objectFit: 'cover',
            borderRadius: 15,
            borderWidth: 1,
            borderColor: 'black',
            borderStyle: 'solid',
          }}
          src={driverImage ? driverImage : null}
        />
      </div>
    </div>
  )
}

export default GoogleMapPin
