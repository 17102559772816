import React from 'react'
import { Text, View } from '@cnd/elements'
import { format } from 'date-fns'
import { curry, find, propEq, values } from 'ramda'

import { Order, OrderState, OrderStates } from '@cnd/common/types'
import { useTranslation } from 'react-i18next'

export const statusesAsNumber = (state: OrderStates) => {
  if (state === 'PAID') return 1
  if (state === 'PREPARING') return 2
  if (state === 'READY_FOR_COLLECTION') return 3
  if (state === 'OUT_FOR_DELIVERY') return 4
  if (state === 'DELIVERED') return 5
  if (state === 'CANCELLED') return 6
  return 0
}

export const isOrderStatus = (status: OrderStates, order) => order.state === status
export const statusHasBeen = curry(
  (statuses: OrderState[], value: OrderStates) => !!find(propEq(value, 'state'), statuses)
)
export const getState = curry((states, value) => find(propEq(value, 'state'), values(states)))

type DynamicOrderStatusProps = {
  order: Order
  isOutForDelivery?: () => boolean
}

const DynamicOrderStatus = ({ order }: DynamicOrderStatusProps) => {
  const { t } = useTranslation()

  const { expectedDeliveryTimeFrom, expectedDeliveryTimeTo, isCollection, statesHistory } = order

  const statusesHasBeen = statusHasBeen(values(statesHistory))
  const delivered = getState(statesHistory, 'DELIVERED')
  const timeDelivered = !delivered
    ? ''
    : `${format(new Date(delivered.createdAtTimestamp), 'HH:mm')} on ${format(
        new Date(delivered.createdAtTimestamp),
        'eee do MMM'
      )}`

  const hasBeenDelivered = statusesHasBeen('DELIVERED')

  return (
    <View style={{ paddingInline: 15, paddingTop: 15 }}>
      {order.state === 'CANCELLED' && (
        <Text size={12} style={{ color: 'orange' }} center>
          {t('Order cancelled')}
        </Text>
      )}
      {hasBeenDelivered && (
        <Text size={12} center>
          {t('Order delivered')}
        </Text>
      )}
      {!isCollection && !hasBeenDelivered && (
        <Text center size={12}>
          {t('Expected arrival')}
        </Text>
      )}
      {isCollection && !hasBeenDelivered && (
        <Text center size={12}>
          {t('Collect')}
        </Text>
      )}

      {!hasBeenDelivered && (
        <View style={{ marginBlock: 6 }} center>
          <Text size={20} weight={700} center>
            {format(new Date(expectedDeliveryTimeFrom), 'HH:mm')} -{' '}
            {format(new Date(expectedDeliveryTimeTo), 'HH:mm')}
          </Text>
        </View>
      )}
      {hasBeenDelivered && (
        <View center style={{ marginBlock: 6 }} row>
          <Text size={20} weight={600} style={{ marginLeft: 8 }}>
            {timeDelivered}
          </Text>
        </View>
      )}
    </View>
  )
}

export default DynamicOrderStatus
