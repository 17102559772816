import { useStripe } from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'
import { PaymentMethod } from '@cnd/common/types'
import { initialize, isGooglePayAvailable } from '@services/stripe'
import { Capacitor } from '@capacitor/core'

const createPaymentRequestOptions = ({ total: amount }) => ({
  country: 'GB',
  currency: 'gbp',
  total: { label: 'My Hiro', amount },
  requestPayerEmail: false,
  requestShipping: false,
  requestPayerName: false,
  requestPayerPhone: false,
})

interface UseGooglePayProps {
  paymentTotal?: number
}

const useGooglePayApp = ({ paymentTotal = 0 }: UseGooglePayProps = {}) => {
  const stripe = useStripe()
  const [canUseGooglePay, setCanUseGooglePay] = useState(false)
  const paymentRequestOptions = createPaymentRequestOptions({ total: paymentTotal })
  const [pending, setPending] = useState(true)

  const updateTotalInPaymentRequest = (total: number) => ({
    total: { label: 'Hiro', amount: total },
  })

  const checkIfCanUseGooglePayMobile = async () => {
    try {
      const platform = Capacitor.getPlatform() as 'android' | 'ios' | 'web'
      if (platform === 'ios') return false
      const canUseApplePayMobile = await isGooglePayAvailable()
      if (canUseApplePayMobile) await initialize()
      return true
    } catch (error) {}
    return false
  }

  const checkIfCanUseGooglePay = async () => {
    setPending(true)
    let canUseGooglePay = await checkIfCanUseGooglePayMobile()
    setCanUseGooglePay(canUseGooglePay)
    setPending(false)
  }

  useEffect(() => {
    checkIfCanUseGooglePay()
  }, [stripe])

  const googlePaymentMethod: PaymentMethod = {
    id: 'google-pay',
    key: 'google-pay',
    brand: 'Google Pay',
    object: 'googlePay',
  }

  return {
    pending,
    paymentRequestOptions,
    canUseGooglePay,
    googlePaymentMethod,
    updateTotalInPaymentRequest,
    createPaymentRequestOptions,
  }
}

export default useGooglePayApp
