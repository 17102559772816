import {
  faAddressBook,
  faAppleAlt,
  faClose,
  faHandshake,
  faHeadSideMask,
  faMobileAndroid,
  faPeopleGroup,
  faSeedling,
  faStream,
  faUserFriends,
  faUserSecret,
  faWandMagicSparkles,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { AnimatePresence, Spacer, Text, View } from '@cnd/elements'
import { useTypedDispatch } from '@store'
import useUser from '@store/useUser'

import { logoutAction } from '@store/userMiddleware'
import useSyncUser from '@hooks/useSyncUser'
import { VERSION } from '@services/version'
import { isApp, isWebsite } from '@services/platform'
import { useTranslation } from 'react-i18next'
import LoginForm from '../Login/LoginForm'
import { useState } from 'react'

import hasCapability from './hasCapability'
import MenuItemLink from './MenuItemLink'
import cardStyle from '@components/CheckoutSteps/cardStyle'
import MenuItem from './MenuItem'
import MenuItemSeparator from './MenuItemSeparator'
import ColoredCard from '@components/ColoredCard'

const AccountMenuLinks = ({ setAccountPage }) => {
  const [renderLogin, setRenderLogin] = useState(false)
  const dispatch = useTypedDispatch()
  const { t } = useTranslation()
  const isLoggedIn = useUser('isLoggedIn')
  const capabilities = useUser('capabilities')
  const canDoFreeSales = hasCapability('freesales', capabilities)

  useSyncUser()

  const checkAndLogout = () => {
    let result = confirm(
      t(
        'Are you sure? This will clear all your data from your device, you can log in again using your mobile number.'
      )
    )
    if (result) logout()
  }

  const checkAndClearData = () => {
    let result = confirm(t('Are you sure? This will clear all your data from your device.'))
    if (result) logout()
  }

  const logout = async () => {
    await dispatch(logoutAction())
    location.reload()
  }

  const login = () => {
    setRenderLogin(true)
  }

  if (renderLogin)
    return (
      <View style={{ overflow: 'scroll' }}>
        <Spacer height={30} />
        <View
          onClick={() => setRenderLogin(false)}
          style={{
            position: 'absolute',
            top: 30,
            borderRadius: 30,
            background: 'white',
            paddingInline: 14,
            paddingBlock: 12,
            border: `1px solid rgba(0,0,0,0.07)`,
          }}
        >
          <FontAwesomeIcon icon={faClose} fontSize={18} />
        </View>
        <Spacer height={40} />
        <Text weight={600} size={28}>
          Login
        </Text>
        <Spacer height={20} />
        <LoginForm next={() => setRenderLogin(false)} />
      </View>
    )

  return (
    <AnimatePresence>
      <View
        key={`account-menu-links`}
        motion
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -70 }}
        style={{ overflow: 'scroll', width: '100%', maxWidth: 400 }}
      >
        <ColoredCard>
          <MenuItem
            last={!isApp}
            onClick={() => setAccountPage('DIETARY_REQUIREMENTS')}
            icon={faSeedling}
            name={t('Dietary Requirements')}
          />
          <MenuItemSeparator />
          <MenuItem
            last={!isApp}
            onClick={() => setAccountPage('MY_ORDERS')}
            icon={faStream}
            name={t('My Orders')}
            notification={`in progress`}
          />
          {isApp && (
            <>
              <MenuItemSeparator />
              <MenuItem last route={'/CollectRelics'} icon={faWandMagicSparkles} name={t('My Relics')} />
            </>
          )}
        </ColoredCard>

        {canDoFreeSales && (
          <MenuItem route={'/FreeSales'} icon={faWandMagicSparkles} name={t('Free Sales')} />
        )}

        <View style={cardStyle}>
          <MenuItemLink
            route={'https://medium.com/@partnerships_49672'}
            icon={faUserFriends}
            name={t('Blog')}
          />
        </View>

        <View style={cardStyle}>
          <MenuItem route={'/AboutUs'} icon={faUserFriends} name={t('About us')} />
          <MenuItemSeparator />
          <MenuItem route={'/YourPrivacy'} icon={faUserSecret} name={t('Your Privacy')} />
          <MenuItemSeparator />
          <MenuItem route={'/Safety'} icon={faHeadSideMask} name={t('Safety')} />
          <MenuItemSeparator />
          <MenuItem last route={'/ContactUs'} icon={faAddressBook} name={t('Contact us')} />
        </View>

        {isWebsite && (
          <ColoredCard>
            <MenuItemLink
              route={'https://apps.apple.com/us/app/hiro-club/id6447646460'}
              icon={faAppleAlt}
              name={t('On iOS? Get the App')}
            />
            <MenuItemSeparator />
            <MenuItemLink
              route={'https://play.google.com/store/apps/details?id=myhiro.club'}
              icon={faMobileAndroid}
              name={t('On Android? Get the App')}
            />
          </ColoredCard>
        )}

        <View style={{ ...cardStyle }}>
          <MenuItem route={'/partners'} icon={faHandshake} name={t('Partners')} />
          <MenuItemSeparator />
          <MenuItem route={'/team'} icon={faPeopleGroup} name={t('Team Members')} />
        </View>
        <View style={{ ...cardStyle }}>
          {isLoggedIn && <MenuItem onClick={checkAndLogout} name={t('Logout')} />}
          {!isLoggedIn && <MenuItem onClick={checkAndClearData} name={t('Clear all data')} />}

          {!isLoggedIn && <MenuItem onClick={login} name={t('Login')} />}
        </View>
        <Spacer height={20} />

        <Text size={10} color="rgba(0,0,0,0.15)">
          {t('v')}
          {VERSION}
        </Text>
      </View>
    </AnimatePresence>
  )
}

export default AccountMenuLinks
