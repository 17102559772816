import { SubHeading, HR, View, ScrollView } from '@cnd/elements'

import LineItem from '@components/LineItem'
import { map } from 'lodash'
import TableLineItem from '@components/TableLineItem'
import { priceToPounds } from '@cnd/common/functions/menu'
import { Order } from '@cnd/common/types'
import { useTranslation } from 'react-i18next'

const BasketSummary = ({
  order: { items, total, deliveryPrice, totalIncludingDelivery },
}: {
  order: Partial<Order>
}) => {
  const { t } = useTranslation()
  return (
    <>
      <SubHeading>{t('Your items')}</SubHeading>
      <View center style={{ width: '100%' }}>
        <ScrollView height="30vh" absolute={false} style={{ width: '100%' }}>
          {map(items, (item) => {
            if (item.type === 'membership') return null
            return <LineItem key={item.key} item={item} />
          })}
        </ScrollView>

        <HR width="90%" />
        <TableLineItem title={t('Sub total')} value={priceToPounds(total)} />
        <TableLineItem title={t('Delivery')} value={priceToPounds(deliveryPrice)} />

        <HR width="100%" />

        <TableLineItem title={t('total payment')} value={priceToPounds(totalIncludingDelivery)} />
      </View>
    </>
  )
}

export default BasketSummary
