import { Switch, View, Text } from '@cnd/elements'

import { useTypedDispatch } from '@store'
import useOrder from '@store/useOrder'
import { useTranslation } from 'react-i18next'
import cardStyle from '@components/CheckoutSteps/cardStyle'

const Cutlery = () => {
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()
  const sendCutlery = useOrder('napkins')
  return (
    <View style={cardStyle} row center spaceBetween pad={6}>
      <Text weight={400} style={{ maxWidth: 200 }} size={14}>
        {t('Add disposable cutlery')}
      </Text>
      <Switch
        width={58}
        height={32}
        toggle={() => dispatch({ type: 'UPDATE_ORDER', payload: { napkins: !sendCutlery } })}
        enabled={sendCutlery}
      />
    </View>
  )
}

export default Cutlery
