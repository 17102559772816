import { useTypedDispatch, useTypedSelector } from '@store'
import { generateHourId } from '@cnd/common/functions/pay'

import { useState } from 'react'
import { getLocationFromChip } from './helpers'

import { Capacitor } from '@capacitor/core'
import { find, values, whereEq } from 'ramda'
import usePostUser from '@hooks/usePostUser'
import { setSidebarBottomIsOpen } from '@store/actions'
import { format, formatISO } from 'date-fns'
import { useSafeAreaInsets } from '@components/SafeAreaView'
import { Button, Spacer, Text, View } from '@cnd/elements'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import Loader from 'react-spinners/ScaleLoader'

const useTodaysHoursSelector = ({ weekNumber, dayName }) =>
  useTypedSelector(
    (state) =>
      find(whereEq({ weekNumber, dayName }), values(state.user.hours)) || {
        tubeExpense: false,
        checkIn: '',
        checkOut: '',
      }
  )

const SelectTimeWorked = () => {
  const insets = useSafeAreaInsets()
  const [currentLocationSearching, setCurrentLocationSearching] = useState(null)
  const [currentLocationSearchingError, setCurrentLocationSearchingError] = useState(null)
  const postUser = usePostUser()

  const dispatch = useTypedDispatch()
  const close = () => dispatch(setSidebarBottomIsOpen(false))

  const { weekNumber, dayName } = useTypedSelector((state) => state.sidebarBottom.data)
  const userId = useTypedSelector((state) => state.user.userId)
  const todaysHours = useTodaysHoursSelector({ weekNumber, dayName })

  const [settingHour, setSettingHour] = useState({ checkIn: false, checkOut: false, tubeExpense: false })

  const checkIn = async () => {
    setSettingHour({ ...settingHour, checkIn: true })

    try {
      const location = await getLocationAndSetSearchingFromChip()
      if (location !== null) {
        const hourId = generateHourId({ userId, weekNumber, dayName })
        await postUser({
          hours: {
            [hourId]: {
              checkIn: formatISO(new Date()),
              location,
              id: hourId,
              weekNumber,
              dayName: dayName,
              userId,
            },
          },
        })
      }
    } catch (error) {}
    setSettingHour({ ...settingHour, checkIn: false })
  }

  const checkOut = async () => {
    setSettingHour({ ...settingHour, checkOut: true })

    try {
      const location = await getLocationAndSetSearchingFromChip()
      if (location !== null) {
        const hourId = generateHourId({ userId, weekNumber, dayName })
        await postUser({ hours: { [hourId]: { checkOut: formatISO(new Date()), location } } })
      }
    } catch (error) {
      console.log('error checkking out')
      console.log(error)
    }

    setSettingHour({ ...settingHour, checkOut: false })
  }

  const logExpenses = async () => {
    setSettingHour({ ...settingHour, tubeExpense: true })

    try {
      const hourId = generateHourId({ userId, weekNumber, dayName })
      await postUser({
        hours: { [hourId]: { tubeExpense: !todaysHours.tubeExpense ? formatISO(new Date()) : '' } },
      })
    } catch (error) {}

    setSettingHour({ ...settingHour, tubeExpense: false })
  }

  const getLocationAndSetSearchingFromChip = async () => {
    setCurrentLocationSearching(true)
    setCurrentLocationSearchingError(false)

    const platform = Capacitor.getPlatform()

    if (platform !== 'android' && platform !== 'ios') {
      setCurrentLocationSearching(false)
      setCurrentLocationSearchingError(true)
      alert(`Please use the app to check in, check in is no longer supported on the website.`)
      return null
    }

    let location
    try {
      const locationFromChip = await getLocationFromChip()
      if (locationFromChip) {
        location = locationFromChip
      } else {
        alert(`You're not close enough to work to check in yet. Please try again later.`)
      }
    } catch (error) {
      setCurrentLocationSearchingError(true)
      return null
    }
    setCurrentLocationSearching(false)
    return location
  }

  return (
    <View
      style={{
        padding: '10px 10px 15px 10px',
        position: 'relative',
        maxWidth: 550,
        paddingBottom: insets.bottom,
        margin: '0 auto',
      }}
    >
      <Text size={20} weight={700} style={{ padding: 15 }}>
        Check In & Out
      </Text>

      <FontAwesomeIcon
        style={{ right: 5, position: 'absolute', top: 0, padding: 20 }}
        icon={faClose}
        fontSize={32}
        onClick={close}
      />
      <Spacer height={10} />

      <View
        row
        spaceBetween
        center
        style={{ padding: 10, margin: 5, background: 'rgba(0,0,0,0.05)', borderRadius: 10, width: '100%' }}
      >
        <Text>Check in time</Text>
        <View>
          {!!todaysHours.checkIn ? (
            <Text weight={500}>{format(new Date(todaysHours.checkIn), 'hh:mm a')}</Text>
          ) : (
            <>
              {!settingHour.checkIn ? (
                <Button small onClick={todaysHours.checkIn ? null : checkIn}>
                  Check In
                </Button>
              ) : (
                <Loader height={15} width={3} />
              )}
            </>
          )}
        </View>
      </View>

      <View
        row
        center
        spaceBetween
        style={{ padding: 10, margin: 5, background: 'rgba(0,0,0,0.05)', borderRadius: 10, width: '100%' }}
      >
        <Text>Finish time</Text>
        <View row center>
          {!!todaysHours.checkOut && (
            <Text style={{ paddingRight: 10 }}>
              {!!todaysHours.checkOut && (
                <Text weight={500}>{format(new Date(todaysHours.checkOut), 'hh:mm a')}</Text>
              )}
            </Text>
          )}

          <>
            {!settingHour.checkOut ? (
              <Button small onClick={checkOut}>
                {!!todaysHours.checkOut ? `Update` : `Check Out`}
              </Button>
            ) : (
              <Loader height={15} width={3} />
            )}
          </>
        </View>
      </View>

      <View>
        <Spacer height={35} />
        <View style={{ padding: 10 }}>
          <Text style={{ maxWidth: 260 }} weight={600}>
            When moving between locations during your shift
          </Text>
          <Spacer height={5} />
          <Text>Request payment for travel</Text>
        </View>

        <View
          center
          row
          spaceBetween
          style={{ padding: 10, margin: 5, background: 'rgba(0,0,0,0.05)', borderRadius: 10 }}
        >
          <View>
            <Text>Add tube journey</Text>
            <Text>(+ £2.70)</Text>
          </View>

          {!settingHour.tubeExpense && (
            <Button
              open
              small
              style={!!todaysHours.tubeExpense ? { color: 'red', borderColor: 'red' } : {}}
              onClick={logExpenses}
            >
              {!todaysHours.tubeExpense ? `Add` : `Remove`}
            </Button>
          )}

          {settingHour.tubeExpense && <Loader height={15} width={3} />}
        </View>
      </View>
    </View>
  )
}

export default SelectTimeWorked
