import { useEffect, useState } from 'react'
import { onSnapshot, doc } from 'firebase/firestore'
import { firestore } from '@services/firebase'

const useRealtimeDocument = ({ collection, doc: docId }, deps) => {
  const [docData, setDocData] = useState(null)

  useEffect(() => {
    onSnapshot(doc(firestore, collection, docId), (docSnapshot) => {
      const source = docSnapshot.metadata.hasPendingWrites ? 'Local' : 'Server'
      if (source === 'Server') setDocData(docSnapshot.data())
    })
  }, deps)

  return docData
}

export default useRealtimeDocument
