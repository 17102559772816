import { LoadingAnimation, View, Warning } from '@cnd/elements'

import api from '@services/api'
import { useTypedDispatch } from '@store'
import { createAndSendError } from '@services/create-sentry-error'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faApplePay } from '@fortawesome/free-brands-svg-icons'
import { createApplePay, presentApplePay, PresentApplePayEnum } from '@services/stripe'
import useOrder, { usePreparedOrder } from '@store/useOrder'
import { isError } from '@cnd/redash'
import { STRIPE_ENV } from '@constants/stripe'
import { split } from 'ramda'

const createIdempotencyKey = (order, paymentMethodId) => {
  const [a, b, c] = split('-', order.id)
  return `${a}-${b}-${c}_${order.humanId}_${order.totalIncludingDelivery}_${order.itemsCount}_${paymentMethodId}`
}

const payForOrderWithApplePay = async (order) => {
  const newOrderTransaction = {
    businessId: !!order.businessId ? order.businessId : `2e04c7bb-d0b2-4275-9b93-7410d76e92bf`,
    idempotencyKey: createIdempotencyKey(order, `apple-wallet`),
    isFor: 'ORDER',
    orderId: order.id,
    amount: order.totalIncludingDelivery,
    currency: 'gbp',
    customerSourceId: `apple-wallet`,
    humanId: order.humanId,
    env: STRIPE_ENV,
    userId: order.userId,
    stripeCustomerId: order.stripeCustomerId,
    customerPhoneNumber: order.customerPhoneNumber,
  }

  let { data: transaction } = await api.post('/transactions', newOrderTransaction)

  if (transaction.state === 'PAID') return transaction

  let confirmedTransaction
  if (transaction.state === 'AWAITING_CONFIRMATION') {
    const clientSecret = transaction.paymentIntentClientSecret

    const amoutntInPoundsAndPence = order.totalIncludingDelivery / 100
    await createApplePay({ paymentIntentClientSecret: clientSecret, amount: amoutntInPoundsAndPence })
    const { paymentResult } = await presentApplePay()

    if (paymentResult === PresentApplePayEnum.Canceled) {
      throw createAndSendError('It looks like something went wrong. No Money has been taken.')
    }
    if (paymentResult === PresentApplePayEnum.Failed) {
      throw createAndSendError('It looks like something went wrong. No Money has been taken.')
    }
    if (paymentResult !== PresentApplePayEnum.Completed) {
      throw createAndSendError(`There was a problem confiming your order. Please try again.`)
    }
    const { data: newTransaction } = await api.post('/transactions', newOrderTransaction)
    confirmedTransaction = newTransaction
  }
  return confirmedTransaction
}

const PayWithApplePayInApp = () => {
  const dispatch = useTypedDispatch()
  const paymentPending = useOrder('paymentPending')
  const paymentError = useOrder('paymentError')
  const order = usePreparedOrder()

  const payWithApplePay = () => {
    dispatch({
      type: 'PAY_FOR_ORDER',
      payload: payForOrderWithApplePay(order),
    })
  }

  if (paymentPending)
    return (
      <View
        center
        style={{ background: 'black', padding: 12, borderRadius: 5, opacity: paymentPending ? 0.95 : 1 }}
      >
        <LoadingAnimation color="white" style={{ height: 24 }} />
      </View>
    )

  return (
    <>
      {paymentError && isError(paymentError) && (
        <Warning style={{ margin: 10 }}>{paymentError.message}</Warning>
      )}
      <View
        center
        style={{ background: 'black', padding: 5, borderRadius: 5, opacity: paymentPending ? 0.7 : 1 }}
        onClick={!paymentPending ? payWithApplePay : () => {}}
      >
        <FontAwesomeIcon icon={faApplePay} fontSize={42} color="white" />
      </View>
    </>
  )
}

export default PayWithApplePayInApp
