import { View } from '@cnd/elements'
import { useEffect, useState } from 'react'
import { SafeArea } from 'capacitor-plugin-safe-area'

export const useSafeAreaInsets = () => {
  const [insets, setInsets] = useState({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  })

  const updateSaveAreaViewInsets = async () => {
    const { insets } = await SafeArea.getSafeAreaInsets()

    setInsets(insets)
  }

  useEffect(() => {
    updateSaveAreaViewInsets()
  }, [])
  return insets
}

const SafeAreaView = ({ children }) => {
  const padding = useSafeAreaInsets()

  return <View style={{ paddingTop: padding.top, paddingBottom: padding.bottom }}>{children}</View>
}

export default SafeAreaView
