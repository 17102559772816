import { View, Free, Text } from '@cnd/elements'

const TableLineItem = ({ title, value, free = false, originalPrice = undefined }) => (
  <View
    row
    spaceBetween
    style={{
      width: '100%',
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 5,
      paddingBottom: 5,
      alignItems: 'center',
    }}
  >
    <div className={'title'}>{title}</div>
    {!free && (
      <View center>
        {originalPrice && originalPrice !== value && (
          <Text style={{ textDecoration: 'line-through', fontSize: 12 }}>{originalPrice}</Text>
        )}
        <Text>{`£${value}`}</Text>
      </View>
    )}
    {free && <Free />}
  </View>
)

export default TableLineItem
