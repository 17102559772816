import { Spacer, View, Warning } from '@cnd/elements'
import api from '@services/api'
import { useStripe } from '@stripe/react-stripe-js'
import PayButton from './PayButton'
import { priceToPounds } from '@cnd/common/functions/menu'
import { createAndSendError } from '@services/create-sentry-error'
import { useTypedDispatch } from '@store'
import { STRIPE_ENV } from '@constants/stripe'
import useOrder, { usePreparedOrder } from '@store/useOrder'
import { createError, isError } from '@cnd/redash'
import { useEffect, useRef } from 'react'
import { usePrevious } from '@uidotdev/usehooks'
import { split } from 'ramda'
import RetroButton from '@components/RetroButton'

const createIdempotencyKey = (order, paymentMethodId) => {
  const [a, b, c] = split('-', order.id)
  const [x, y] = split('_', paymentMethodId)
  return `${a}-${b}-${c}_${order.humanId}_${order.totalIncludingDelivery}_${order.itemsCount}_${y.substring(
    0,
    4
  )}`
}

const payForOrderWithCustomerCard = async (paymentMethod, stripe, order) => {
  const newOrderTransaction = {
    businessId: `2e04c7bb-d0b2-4275-9b93-7410d76e92bf`,
    idempotencyKey: createIdempotencyKey(order, paymentMethod.id),
    isFor: 'ORDER',
    orderId: order.id,
    amount: order.totalIncludingDelivery,
    currency: 'gbp',
    customerSourceId: paymentMethod.id,
    humanId: order.humanId,
    env: STRIPE_ENV,
    userId: order.userId,
    stripeCustomerId: order.stripeCustomerId,
    customerPhoneNumber: order.customerPhoneNumber,
  }

  let { data: transaction } = await api.post('/transactions', newOrderTransaction)

  console.log({ transaction })

  const { paymentIntentNextAction, paymentIntentClientSecret } = transaction

  if (paymentIntentNextAction && paymentIntentNextAction.type === 'use_stripe_sdk') {
    const result = await stripe.confirmCardPayment(paymentIntentClientSecret)

    if (result?.paymentIntent?.status !== 'succeeded') throw createAndSendError(`3D Secure failed`)
    if (result?.error) throw createAndSendError(`There was a problem with 3D Secure. Please try again.`)

    const transactionResult = await api.post(`/transactions`, { ...newOrderTransaction, confirm: true })
    transaction = transactionResult.data
  }

  console.log({ tx: transaction })

  if (transaction.state !== 'PAID')
    throw createError(`An unknown error has occured. You've not been charged. Please try again.`)

  return transaction
}

const PayWithCard = ({ paymentMethod }) => {
  const ref = useRef({ canPayWithCard: false })
  const stripe = useStripe()
  const dispatch = useTypedDispatch()
  const paymentError = useOrder('paymentError')

  const paymentPending = useOrder('paymentPending')
  const wasPaymentPending = usePrevious(paymentPending)
  const order = usePreparedOrder()

  const payWithCard = async () => {
    if (ref.current.canPayWithCard) return
    dispatch({
      type: 'PAY_FOR_ORDER',
      payload: payForOrderWithCustomerCard(paymentMethod, stripe, order),
    })
  }

  useEffect(() => {
    if (wasPaymentPending && !paymentPending) {
      ref.current.canPayWithCard = false
    }
  }, [paymentPending, wasPaymentPending])

  return (
    <View>
      {paymentError && isError(paymentError) && (
        <Warning style={{ margin: 10 }}>{paymentError.message}</Warning>
      )}

      <Spacer height={10} />
      <RetroButton disabled={paymentPending || !order.totalIncludingDelivery} onClick={payWithCard} invert>
        {`Pay with Card`}
      </RetroButton>
      {/* <PayButton
        isPaying={paymentPending}
        formComplete={true}
        amount={priceToPounds(order.totalIncludingDelivery)}
        pay={payWithCard}
      /> */}
      <Spacer height={15} />
    </View>
  )
}

export default PayWithCard
