import { View } from '@cnd/elements'

const SidebarItemContainer = ({ children, last = false, style = {}, ...rest }) => (
  <View
    motion
    row
    alignCenter
    spaceBetween
    style={{
      cursor: 'pointer',
      paddingInline: 10,
      paddingBlock: 8,
      width: '100%',
      ...style,
    }}
    {...rest}
  >
    {children}
  </View>
)
export default SidebarItemContainer
