import { useStripe } from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'
import { PaymentMethod } from '@cnd/common/types'

const createPaymentRequestOptions = ({ total: amount }) => ({
  country: 'GB',
  currency: 'gbp',
  total: { label: 'My Hiro', amount },
  requestPayerEmail: false,
  requestShipping: false,
  requestPayerName: false,
  requestPayerPhone: false,
})

interface UseGooglePayProps {
  paymentTotal?: number
}

const useGooglePayWeb = ({ paymentTotal = 0 }: UseGooglePayProps = {}) => {
  const stripe = useStripe()
  const [canUseGooglePay, setCanUseGooglePay] = useState(false)
  const paymentRequestOptions = createPaymentRequestOptions({ total: paymentTotal })
  const [pending, setPending] = useState(true)

  const updateTotalInPaymentRequest = (total: number) => ({
    total: { label: 'My Hiro', amount: total },
  })

  const checkIfCanUseGooglePayWeb = async ({ stripe }) => {
    if (!stripe) return false
    const paymentRequest = stripe.paymentRequest(paymentRequestOptions)

    try {
      const result = await paymentRequest.canMakePayment()
      if (result && result.googlePay) return true
    } catch (error) {}
    return false
  }

  const checkIfCanUseGooglePay = async ({ stripe }) => {
    setPending(true)
    let canUseGooglePay = await checkIfCanUseGooglePayWeb({ stripe })
    setCanUseGooglePay(canUseGooglePay)
    setPending(false)
  }

  useEffect(() => {
    checkIfCanUseGooglePay({ stripe })
  }, [stripe])

  const googlePaymentMethod: PaymentMethod = {
    id: 'google-pay',
    key: 'google-pay',
    brand: 'Google Pay',
    object: 'googlePay',
  }

  return {
    pending,
    paymentRequestOptions,
    canUseGooglePay,
    googlePaymentMethod,
    updateTotalInPaymentRequest,
    createPaymentRequestOptions,
  }
}

export default useGooglePayWeb
