import { useTypedSelector } from '@store'
import { InitialState } from './initialState'
import useUser, { useStripeCustomerId } from './useUser'

import { filter, includes, values } from 'ramda'
import { Order } from '@cnd/common/types'

const ACTIVE_ORDER_STATES = ['OUT_FOR_DELIVERY', 'PAID', 'PREPARING', 'CONFIRMED', 'READY_FOR_COLLECTION']

export const ordersInProgress = (orders: { [key: string]: Order }) =>
  filter(({ state }) => includes(state, ACTIVE_ORDER_STATES), values(orders)).length > 0

const useOrder = <K extends keyof InitialState['user']['order']>(
  orderProp: K
): InitialState['user']['order'][K] => useTypedSelector((state) => state.user?.order[orderProp])

export const usePreparedOrder = () => {
  const userId = useUser('userId')
  const stripeCustomerId = useStripeCustomerId()
  const phoneNumber = useUser('phoneNumber')
  const order = useTypedSelector((state) => state.user.order)
  return {
    ...order,
    userId,
    customerPhoneNumber: order.customerPhoneNumber ? order.customerPhoneNumber : phoneNumber,
    stripeCustomerId,
  }
}

export default useOrder
