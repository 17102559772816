import { View, Spacer, Text } from '@cnd/elements'
import { setSidebarBottomIsOpen } from '@store/actions'
import { useTypedDispatch, useTypedSelector } from '@store'
import { useSafeAreaInsets } from '@components/SafeAreaView'
import { format, formatISO, set } from 'date-fns'
import { find, indexOf, map, propEq } from 'ramda'
import useOrder from '@store/useOrder'
import useClosestLocation from '@store/useClosestLocation'
import { PreorderDeliveryTimeSlots } from '@store/preorderDeliveryTimeSlots'
import { useTranslation } from 'react-i18next'

const isFirstHour = (a) => {
  const r = indexOf(':00', a)
  return r < 5 && r > -1
}

const SelectTime = ({ pickTime, time: { timeName, hour, minutes } }) => {
  const { t } = useTranslation()
  return (
    <View
      motion
      onClick={() => pickTime(hour, minutes)}
      row
      style={{
        padding: 15,
        background: isFirstHour(timeName) ? 'rgba(0,0,0,0.04)' : 'rgba(0,0,0,0.08)',
        marginBottom: 10,
        borderRadius: 10,
      }}
      whileTap={{ opacity: 0.7, scale: 0.99 }}
      spaceBetween
    >
      <Text bold color="black">
        {timeName.substring(0, timeName.lastIndexOf(' '))}{' '}
        {t(timeName.substring(timeName.lastIndexOf(' ') + 1, timeName.length))}
      </Text>

      <View row>
        <Text color="black" weight={400}>
          {t('pick')}
        </Text>
      </View>
    </View>
  )
}

const renderTime = (pickTime) => (time) => <SelectTime key={time.timeName} {...{ pickTime, time }} />

const EditNewUserDeliveryTime = () => {
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()
  const insets = useSafeAreaInsets()
  const isFor = useTypedSelector((s) => s.sidebarBottom.data?.isFor)
  const expectedDeliveryTimeFrom = useOrder('expectedDeliveryTimeFrom')
  const searchExpectedDeliveryTimeFrom = useTypedSelector((s) => s.locations.searchExpectedDeliveryTimeFrom)
  const { preorderDeliveryDays } = useClosestLocation()

  const day = format(new Date(searchExpectedDeliveryTimeFrom), 'EEEE')
  const preorderDeliveryTimeSlots = find<any>(propEq(day, 'dayName'), preorderDeliveryDays)
    .timeSlots as PreorderDeliveryTimeSlots[]

  const close = () => dispatch(setSidebarBottomIsOpen(false))

  const pickTime = (hour, minutes) => {
    if (isFor === 'locations') {
      dispatch({
        type: 'SET_LOCATION_SEARCH_DEL_TIME',
        payload: {
          searchExpectedDeliveryTimeFrom: formatISO(
            set(new Date(searchExpectedDeliveryTimeFrom), {
              hours: hour,
              minutes,
            })
          ),
          searchExpectedDeliveryTimeTo: formatISO(
            set(new Date(searchExpectedDeliveryTimeFrom), {
              hours: hour,
              minutes: minutes + 20,
            })
          ),
        },
      })
    } else {
      dispatch({
        type: 'UPDATE_ORDER',
        payload: {
          isPreOrder: true,
          expectedDeliveryTimeFrom: formatISO(
            set(new Date(expectedDeliveryTimeFrom), {
              hours: hour,
              minutes,
            })
          ),
          expectedDeliveryTimeTo: formatISO(
            set(new Date(expectedDeliveryTimeFrom), {
              hours: hour,
              minutes: minutes + 20,
            })
          ),
        },
      })
    }

    close()
  }

  return (
    <View
      style={{
        backgroundColor: 'white',
        maxWidth: 550,
        width: '100%',
        margin: '0 auto',
        paddingBottom: insets.bottom + 25,
        position: 'relative',
      }}
    >
      <View
        spaceBetween
        center
        row
        style={{
          position: 'fixed',
          left: 0,
          right: 0,
          background: 'white',
          zIndex: 50,
          border: '1px solid rgba(0,0,0,0.05)',
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
        }}
      >
        <Text bold size={20} style={{ margin: 20, display: 'block' }}>
          {t('Delivery Time')}
        </Text>
      </View>

      <View style={{ padding: 10 }}>
        <Spacer height={90} />
        {preorderDeliveryTimeSlots.length > 0 && map(renderTime(pickTime), preorderDeliveryTimeSlots)}
        {preorderDeliveryTimeSlots.length === 0 && (
          <View center>
            <Spacer height={60} />
            <Text>{t('No times available, please select another day.')}</Text>
          </View>
        )}
      </View>

      <Spacer height={355} />
    </View>
  )
}
export default EditNewUserDeliveryTime
