import { Button, LoadingAnimation, Text, View, Spacer, AbsoluteView, BackdropDismisser } from '@cnd/elements'
import Lottie from 'lottie-react'
import prizeAnimation from '../../../public/prize.json'
import { useSafeAreaInsets } from '@components/SafeAreaView'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useTypedDispatch, useTypedSelector } from '@store'

import api from '@services/api'
import { Device } from '@capacitor/device'
import { reduce, values } from 'ramda'
import { Share } from '@capacitor/share'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons'
import { createError, tryManyTimes } from '@cnd/redash'
import ColoredButton from '@components/ColoredButton'

const wasOrderFree = (order) =>
  reduce(
    (wasFree, payment) => {
      if (payment.orderWasOnTheHouse) wasFree = true
      return wasFree
    },
    false,
    values(order.payment)
  )

const WonFreeOrder = () => {
  const [visible, setVisible] = useState(true)
  const [sharing, setSharing] = useState(false)
  const [error, setError] = useState(null)
  const [canShare, setCanShare] = useState(false)

  if (error) setError(createError(`There was a problem confirming your order, please try again.`))

  setTimeout(() => {
    Share.canShare()
      .then((enabled) => {
        setCanShare(enabled.value)
      })
      .catch((error) => {
        setCanShare(false)
      })
  }, 2500)

  const share = async () => {
    setSharing(true)
    try {
      Share.share({
        text: 'Boom, just got my order for free on My Hiro, ',
      })
    } catch (error) {}
    setSharing(false)
  }

  const close = () => setVisible(false)

  if (!visible) return null

  return (
    <AbsoluteView>
      <BackdropDismisser opacity={0.8} style={{ zIndex: 10 }} close={close} />
      <View style={{ margin: 20, zIndex: 10, background: 'white', borderRadius: 30 }}>
        <View>
          <View center style={{ marginTop: 35, marginBottom: 10 }}>
            <Text weight={600} size={22}>
              BOOM!
            </Text>
          </View>
          <View center>
            <View center style={{ maxWidth: 350 }}>
              <Lottie animationData={prizeAnimation} />
            </View>
          </View>
        </View>
        <View center style={{ marginBottom: 65, marginTop: 45, width: '100%' }}>
          <Text center size={32} weight={800}>
            YOU WON
          </Text>
          <Text center size={22} weight={500}>
            Your order is free!
          </Text>

          <Spacer height={20} />
          <Text center size={16} weight={400} style={{ maxWidth: 280 }}>
            My Hiro members get{' '}
            <Text weight={600} block={false}>
              1 in every 100
            </Text>{' '}
            of all order orders free.
          </Text>
          <Spacer height={10} />
          <Text>Today was your day!</Text>
          <Spacer height={20} />
          <View row>
            {true && (
              <ColoredButton open onClick={!sharing ? share : () => {}} style={{ marginRight: 10 }}>
                {!sharing ? (
                  <View row center>
                    <FontAwesomeIcon icon={faArrowUpFromBracket} fontSize={22} />
                  </View>
                ) : (
                  <LoadingAnimation style={{ height: 15, width: 3, radius: 4 }} />
                )}
              </ColoredButton>
            )}

            <ColoredButton full onClick={close} color="#540000">
              <Text color={'white'} weight={500} size={18} style={{ marginInline: 20 }}>
                Awesome
              </Text>
            </ColoredButton>
          </View>
        </View>
      </View>
    </AbsoluteView>
  )
}

export default WonFreeOrder
