import { View } from '@cnd/elements'
import PhoneInput from '@components/PhoneInput'
import { memo } from 'react'

const LoginPhoneInput = ({ phoneNumber, setPhoneNumber }) => {
  return (
    <View style={{ borderRadius: 4, border: '1px solid rgba(0,0,0,0.1)', paddingInline: 7, paddingBlock: 5 }}>
      <PhoneInput
        defaultCountry="GB"
        countries={[
          'GB',
          'IT',
          'IE',
          'CA',
          'CR',
          'JM',
          'MX',
          'VG',
          'IN',
          'PH',
          'TH',
          'AT',
          'BE',
          'DK',
          'FR',
          'DE',
          'PL',
          'PT',
          'ES',
          'SE',
          'AR',
          'BR',
          'CL',
          'CO',
          'FK',
          'PE',
          'VE',
          'ZA',
        ]}
        country="GB"
        type="tel"
        name="phone"
        placeholder="Phone number"
        value={phoneNumber}
        onChange={setPhoneNumber}
      />
    </View>
  )
}

export default memo(LoginPhoneInput)
