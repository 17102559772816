import { Text, View } from '@cnd/elements'
import colors from '@constants/colors'

const RetroButton = ({
  onClick,
  children,
  background = 'none',
  fullWidth = false,
  onDisabledClick = () => {},
  disabled = false,
  invert = false,
}) => {
  return (
    <View
      style={{
        position: 'relative',
        cursor: 'pointer',
        width: fullWidth ? '100%' : 'inherit',
        opacity: disabled ? 0.3 : 1,
      }}
      motion
      whileTap={{ scale: 0.96 }}
    >
      <View
        style={{
          borderBottomLeftRadius: 3,
          borderBottomRightRadius: 3,
          background: colors.nBlack(),
          height: 2,
          width: '95%',
          position: 'absolute',
          bottom: -2,
          left: '50%',
          transform: `translate(-50%,0)`,
        }}
      />
      <View
        motion
        whileTap={{ y: 3 }}
        onClick={disabled && !!onDisabledClick ? onDisabledClick : disabled ? null : onClick}
        style={{
          borderRadius: 3,
          border: `2px solid ${colors.nBlack()}`,
          paddingBlock: 10,
          background: invert ? 'black' : background,
          width: fullWidth ? '100%' : 'inherit',
        }}
        center
      >
        {typeof children === 'string' && (
          <Text weight={500} size={18} color={invert ? 'white' : 'black'}>
            {children}
          </Text>
        )}
        {typeof children !== 'string' && children}
      </View>
    </View>
  )
}

export default RetroButton
