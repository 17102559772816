import { formatPhoneNumber } from 'react-phone-number-input'
import { setDoubleSidebarBottom } from '@store/actions'
import { SubHeading, sectionStyle, WhiteButton, View, Text, Button, HR } from '@cnd/elements'
import { useTypedDispatch } from '@store'
import useUser from '@store/useUser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import { login } from '@store/userMiddleware'
import { useTranslation } from 'react-i18next'
import CheckoutSubtitle from '@components/CheckoutSteps/CheckoutSubtitle'
import cardStyle from '@components/CheckoutSteps/cardStyle'
import colors from '@constants/colors'

const Property = ({ children }) => (
  <View
    style={{
      marginLeft: 10,
      marginRight: 15,
      display: 'inline-block',
      color: '#303030',
    }}
  >
    {children}
  </View>
)

const usePhoneNumber = (): { phoneNumber: string; state: 'linked' | 'relink' | 'new' } => {
  const phoneNumber = useUser('phoneNumber')
  const accessToken = useUser('accessToken')
  const userId = useUser('userId')
  const isLoggedIn = useUser('isLoggedIn')

  useEffect(() => {
    if (userId && accessToken) login(accessToken, userId)
  }, [userId, accessToken])

  if (isLoggedIn) {
    return { phoneNumber, state: 'linked' }
  }

  if (accessToken && phoneNumber && !isLoggedIn) {
    return { phoneNumber, state: 'relink' }
  }

  return { phoneNumber: '', state: 'new' }
}

const UpdateMe = ({ enabled = true }) => {
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()
  const { phoneNumber, state } = usePhoneNumber()

  const email = useUser('email')

  const editEmail = () => {
    if (!phoneNumber) alert('Please add a phone number first')
    if (!!phoneNumber) dispatch(setDoubleSidebarBottom({ content: 'UpdateEmail', isOpen: true }))
  }

  const editPhoneNumber = () => {
    dispatch(setDoubleSidebarBottom({ content: 'LoginModal', isOpen: true, height: '90vh' }))
  }

  return (
    <View style={!enabled ? { opacity: 0, pointerEvents: 'none' } : {}}>
      <CheckoutSubtitle>{t('Your details')}</CheckoutSubtitle>
      <View style={{ ...cardStyle, padding: 0, borderColor: colors.nBlack() }}>
        <View
          onClick={editPhoneNumber}
          alignCenter
          row
          pad={8}
          spaceBetween
          style={{
            paddingTop: 15,
            paddingLeft: 15,
            paddingRight: 15,
            paddingBottom: 15,
            background: !phoneNumber ? colors.nBlack() : 'white',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <View row alignCenter>
            <FontAwesomeIcon
              icon={faPhone}
              color={!!phoneNumber ? colors.nBlack() : colors.nWhite}
              fontSize={16}
            />
            <Text
              style={{ marginLeft: 10 }}
              weight={400}
              size={14}
              color={!!phoneNumber ? colors.nBlack() : colors.nWhite}
            >
              {!!phoneNumber ? formatPhoneNumber(phoneNumber) : t('Add Phone Number')}
            </Text>
          </View>

          {(state === 'new' || state === 'relink') && (
            <Text size={14} weight={400} color={!!phoneNumber ? 'grey' : colors.nWhite}>
              {state === 'relink' ? t('confirm') : t('*required')}
            </Text>
          )}
        </View>
        <HR width="100%" height={1} />
        <View
          alignCenter
          spaceBetween
          row
          pad={8}
          style={{
            paddingTop: 15,
            paddingLeft: 15,
            paddingRight: 15,
            paddingBottom: 15,
          }}
          onClick={editEmail}
        >
          <View row alignCenter>
            <FontAwesomeIcon icon={faEnvelope} color={colors.nBlack()} fontSize={16} />
            <Text style={{ marginLeft: 10 }} size={14} weight={400}>
              {email ? email : t('Email')}
            </Text>
          </View>
          <View style={{ marginLeft: 10 }}>
            <Text size={14} weight={400}>
              {!email ? t('optional') : t('change')}
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}
export default UpdateMe
