import React, { useEffect, useState } from 'react'

import { setDoubleSidebarBottom } from '@store/actions'

import { Spacer, Text, View } from '@cnd/elements'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWalking } from '@fortawesome/free-solid-svg-icons'
import UserLocation from '../../UserLocation'
import useOrder from '@store/useOrder'
import api from '@services/api'
import { useTypedDispatch } from '@store'
import { useTranslation } from 'react-i18next'
import CheckoutSubtitle from '@components/CheckoutSteps/CheckoutSubtitle'
import cardStyle from '@components/CheckoutSteps/cardStyle'
import ColoredCard from '@components/ColoredCard'

const CollectFrom = () => {
  const { t } = useTranslation()
  const { postcode, minsFromLocation, streetAddress1, name } = useOrder('collectionLocation')
  const dispatch = useTypedDispatch()

  const [latLng, setLatLng] = useState(null)

  const addDeliveryLocation = () => {
    dispatch(setDoubleSidebarBottom({ isOpen: true, content: 'AddDeliveryLocation' }))
  }

  const getCollectionLocation = async () => {
    const { data: { lat, lng } = {} } = await api.post('/GetLatLng', { postcode })
    setLatLng({ lat, lng })
  }

  useEffect(() => {
    getCollectionLocation()
  }, [])

  return (
    <View>
      <CheckoutSubtitle>{t('Collect from')}</CheckoutSubtitle>

      <ColoredCard style={{ padding: 0, overflow: 'hidden' }}>
        <View
          center
          spaceBetween
          style={{ padding: 8, background: 'white', margin: 10, borderRadius: 6 }}
          onClick={addDeliveryLocation}
        >
          <Text weight={400} size={15}>
            You're a {minsFromLocation} {t('mins walk')} <FontAwesomeIcon icon={faWalking} fontSize={14} />{' '}
            from
          </Text>
          <Spacer height={12} />
          <Text weight={400} size={16}>
            My Hiro, {name}
          </Text>

          <View>
            <Text>
              {streetAddress1},{postcode}
            </Text>
          </View>
        </View>
        {latLng && (
          <UserLocation
            location={{ latLng }}
            ourLocation={{ latLng }}
            center={latLng}
            height={'250px'}
            width={'100%'}
          />
        )}
      </ColoredCard>
    </View>
  )
}
export default CollectFrom
