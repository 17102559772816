import { useFunDispatch, useTypedSelector } from '@store'

import Basket from './Basket'
import CheckoutPage from './CheckoutPage'
import PlaceOrder from '@components/CheckoutSteps/PlaceOrder'
import useOrder from '@store/useOrder'
import PlacingOrder from './PlacingOrder'
import CheckoutOrder from './CheckoutOrder'
import { head, values } from 'ramda'
import useUser from '@store/useUser'
import useRealtimeDocument from '@hooks/useRealtimeDocument'

const Checkout = () => {
  const dispatch = useFunDispatch()
  const checkoutStep = useTypedSelector((state) => state.route.checkoutStep)
  const paymentPending = useOrder('paymentPending')
  const orderId = useOrder('id')
  const userId = useUser('userId')
  const orderInProgress = useTypedSelector((state) => head(values(state.ordersInProgress)))

  const goToCheckout = () => dispatch('SET_CHECKOUT_STEP', 'CHECKOUT')
  const goToBasket = () => dispatch('SET_CHECKOUT_STEP', 'BASKET')
  const back = () => dispatch('RESET_BOTTOMBAR')()

  const transaction = useRealtimeDocument({ collection: `orders-in-progress`, doc: orderId }, [
    orderId,
    paymentPending,
  ])

  if (transaction?.state === 'PAID' || (transaction?.state === 'CONFIRMED' && !orderInProgress))
    return (
      <CheckoutPage>
        <PlacingOrder transactionId={transaction?.transactionId} userId={userId} />
      </CheckoutPage>
    )

  if (!!orderInProgress)
    return (
      <CheckoutPage>
        <CheckoutOrder orderId={orderInProgress.id} back={back} />
      </CheckoutPage>
    )

  return (
    <CheckoutPage>
      {checkoutStep === 'BASKET' && <Basket {...{ goToCheckout, back }} />}
      {checkoutStep === 'CHECKOUT' && <PlaceOrder {...{ back: goToBasket }} />}
    </CheckoutPage>
  )
}
export default Checkout
