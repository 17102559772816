import { useFunDispatch, useTypedSelector } from '@store'
import { useQuery } from '@tanstack/react-query'
import { getTempUser, login } from '@store/userMiddleware'
import { useEffect } from 'react'
import { difference } from '@cnd/redash'
import { values } from 'ramda'

const useSyncUser = () => {
  const dispatch = useFunDispatch()
  const user = useTypedSelector((s) => s.user)
  const { userId, accessToken, isLoggedIn, pending } = user

  useQuery(
    ['user', isLoggedIn, userId, accessToken],
    async () => {
      let queryUser = {}
      console.log('useQuery Maybe logging in', !isLoggedIn && !pending, isLoggedIn && !pending)
      if (!isLoggedIn && !pending) queryUser = await getTempUser({ userId })
      if ((isLoggedIn && !pending) || (!!userId && !!accessToken && !pending))
        queryUser = await login(accessToken, userId)

      console.log(
        'useQuery Run now updating',
        queryUser && values(difference(user, queryUser)).length > 0,
        values(difference(user, queryUser)).length,
        user,
        queryUser,
        difference(user, queryUser)
      )

      if (queryUser && values(difference(user, queryUser)).length > 0) {
        await dispatch('SET_USER', queryUser)
      }

      return queryUser
    },
    { staleTime: 2000, refetchOnMount: 'always', refetchOnWindowFocus: true }
  )

  return null
}

export default useSyncUser
