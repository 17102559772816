import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'
import { LoadingAnimation } from '@cnd/elements'

const LoadStripeWeb = ({ children }) => {
  const [stripe, setStripe] = useState<any>(false)

  useEffect(() => {
    loadStripe(process.env.STRIPE_PUBLIC_APIKEY).then((stripe) => {
      setStripe(stripe)
    })
  }, [])

  if (!stripe) return <LoadingAnimation />

  return <Elements stripe={stripe}>{children}</Elements>
}

export default LoadStripeWeb
