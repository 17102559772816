import { Spacer, View, ScrollView, Text } from '@cnd/elements'

import useUser from '@store/useUser'
import useSyncUser from '@hooks/useSyncUser'
import usePostUser from '@hooks/usePostUser'
import DietaryRequirementSwitch from './DietaryRequirementSwitch'

const AccountDietaryRequirements = () => {
  useSyncUser()
  const postUser = usePostUser()
  const pending = useUser('pending')

  const isVegan = useUser('isVegan')
  const isVeggie = useUser('isVeggie')
  const isHalal = useUser('isHalal')
  const hideFoodsICantEat = useUser('hideFoodsICantEat')

  const toggleVegan = () => postUser({ isVegan: !isVegan })
  const toggleVeggie = () => postUser({ isVeggie: !isVeggie })
  const toggleHalal = () => postUser({ isHalal: !isHalal })
  const toggleHideFoodsICantEat = () => postUser({ hideFoodsICantEat: !hideFoodsICantEat })

  return (
    <View
      motion
      key={`account-dietary-requirements`}
      initial={{ opacity: 0, x: -70 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -70 }}
      style={{ width: '100%' }}
    >
      <ScrollView paddingTop={0} absolute={false}>
        <View center style={{ width: '100%' }}>
          <View center style={{ maxWidth: 350, marginBottom: 10, marginTop: 20 }}>
            <Text center>Customise the menu just for you.</Text>
          </View>

          <DietaryRequirementSwitch label={'Vegan'} value={isVegan} toggle={toggleVegan} pending={pending} />
          <Spacer height={0} />
          <DietaryRequirementSwitch
            label={'Vegetarian'}
            value={isVeggie}
            toggle={toggleVeggie}
            pending={pending}
          />
          <Spacer height={0} />
          <DietaryRequirementSwitch label={'Halal'} value={isHalal} toggle={toggleHalal} pending={pending} />
          <Spacer height={0} />
          <DietaryRequirementSwitch
            label={'Hide Other Foods'}
            value={hideFoodsICantEat}
            toggle={toggleHideFoodsICantEat}
            pending={pending}
            warning={
              <Text>
                Warning, switching this switch will mean you will not see any menu items that are not in your
                allowed diet
              </Text>
            }
          />
        </View>
      </ScrollView>
    </View>
  )
}
export default AccountDietaryRequirements
