import { useState, useEffect } from 'react'
import Calendar from 'react-calendar'

import { SubHeading, GreenButton, Container, Error, View } from '@cnd/elements'

import { setSidebarBottomIsOpen } from '@store/actions'
import { setPreOrder } from '@store/orderActions'

import api from '@services/api'
import { useTypedDispatch, useTypedSelector } from '@store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import * as Sentry from '@sentry/nextjs'

const LaterDelivery = () => {
  const isOpen = useTypedSelector((state) => state.sidebarBottom.isOpen)
  const [availableDeliveryTimeSlots, setAvailableDeliveryTimeSlots] = useState([])
  const [deliverySlotsLoading, setDeliverySlotsLoading] = useState(false)
  const [deliverySlotsError, setDeliverySlotsError] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setCalVal(new Date())
      setTime('unset')
      setDateSaved(false)
      setTimeIsLate(false)
    }
  }, [isOpen])

  const [calDate, setCalVal] = useState(new Date())
  const [time, setTime] = useState('unset')
  const [timeIsLate, setTimeIsLate] = useState(false)
  const dispatch = useTypedDispatch()

  const [dateSaved, setDateSaved] = useState(false)

  const getDeliveryTimeSlots = async () => {
    setDeliverySlotsLoading(true)
    setDeliverySlotsError(false)
    try {
      let {
        data: { deliveryTimeSlots },
        status,
      } = await api.post(`/DeliveryTimeSlots`, { date: calDate.valueOf() })

      if (status !== 200 || !deliveryTimeSlots)
        throw { error: { message: 'There was a problem loading the delivery time slots' } }

      let today = new Date()

      if (today.toDateString() === calDate.toDateString()) {
        let hours = calDate.getHours()
        deliveryTimeSlots = deliveryTimeSlots.filter((time) => parseInt(time.value.split(':')[0]) > hours)
      }

      setAvailableDeliveryTimeSlots(deliveryTimeSlots)
    } catch (error) {
      Sentry.captureException(error)
      setDeliverySlotsError(true)
    }
    setDeliverySlotsLoading(false)
  }
  const saveDate = () => {
    getDeliveryTimeSlots()
    setDateSaved(true)
  }

  const close = () => {
    dispatch(setSidebarBottomIsOpen(false))
  }

  const updateTime = (e) => {
    const value = e.target.value

    const hour = parseInt(value.split(':')[0])

    if (hour > 23 || hour < 8) {
      setTimeIsLate(true)
    } else {
      setTimeIsLate(false)
    }
    setTime(value)
  }

  const saveTime = () => {
    calDate.setHours.apply(null, ...time.split(':').map(parseFloat))
    dispatch(setPreOrder(true))
    //dispatch(setProposedDeliveryTimeAndLimit(calDate.valueOf(), calDate.valueOf() + 20 * 60 * 1000))
    close()
  }

  if (dateSaved)
    return (
      <View style={{ padding: '0px 10px 15px 10px', position: 'relative' }}>
        <View row>
          <SubHeading>Select arrival time</SubHeading>
        </View>
        <FontAwesomeIcon
          style={{ right: 0, position: 'absolute', top: 0, padding: 10 }}
          icon={faClose}
          fontSize={32}
          onClick={close}
        />
        <View row>
          <div style={{ width: '100%', height: 10 }}></div>
          {deliverySlotsLoading && <div style={{ padding: 10 }}>loading delivery times</div>}
          {deliverySlotsError && <Error>There was a problem loading delivery times</Error>}
          {availableDeliveryTimeSlots.length > 0 && (
            <Container>
              <select
                style={{
                  width: '100%',
                  padding: '10px',
                  height: '40px',
                  fontSize: '16px',
                }}
                value={time}
                onChange={updateTime}
              >
                <option value="unset">Select delivery time</option>
                {availableDeliveryTimeSlots.map((item) => (
                  <option key={item.value} value={item.value} disabled={item.disabled}>
                    {item.label}
                  </option>
                ))}
              </select>
            </Container>
          )}
          {timeIsLate && (
            <View row>
              <Error>
                Your order will come when most people <b>are sleeping</b>!<b> Are you sure?</b>
              </Error>
            </View>
          )}
        </View>
        <View row>
          <GreenButton disabled={time === 'unset'} onClick={saveTime}>
            Save
          </GreenButton>
        </View>
      </View>
    )

  return (
    <View style={{ padding: '0px 10px 15px 10px', position: 'relative' }}>
      <View row>
        <SubHeading>Select arrival date</SubHeading>
      </View>
      <FontAwesomeIcon
        style={{ right: 0, position: 'absolute', top: 0, padding: 10 }}
        icon={faClose}
        fontSize={32}
        onClick={close}
      />
      <style>
        {`
        .react-calendar{width:100%}
          .later-deliver-calender{
            border-radius: 5px;
            border: 1px solid #eaeaea;
            box-shadow: 0 0 15px -12px black;
            margin-bottom: 15px;
          }
          .react-calendar__navigation__arrow{
            font-size: 25px;
          }
      `}
      </style>
      <View row>
        <View style={{ padding: '10px 0px 10px 0px' }}>
          <Calendar
            className={'later-deliver-calender'}
            minDate={new Date()}
            onChange={setCalVal}
            value={calDate}
          />
        </View>
      </View>
      <View row>
        <GreenButton onClick={saveDate}>Next</GreenButton>
      </View>
    </View>
  )
}
export default LaterDelivery
