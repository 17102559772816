import { ScrollView, Spacer, Text, View } from '@cnd/elements'

import { useState } from 'react'
import LineItem from '@components/LineItem'
import useUser from '@store/useUser'
import useOrder from '@store/useOrder'
import { addItemToOrder, removeItemFromOrderByKey } from '@store/orderActions'

import { isMembershipInItems, memberships, priceToPounds } from '@cnd/common/functions/menu'
import { map, values } from 'ramda'
import { useTypedDispatch } from '@store'
import { useTranslation } from 'react-i18next'
import { FaRegLaughWink } from 'react-icons/fa'
import colors from '@constants/colors'
import CollectFrom from '@components/CheckoutSteps/PlaceOrderForm/CollectFrom'
import DeliverTo from '@components/CheckoutSteps/PlaceOrderForm/DeliverTo'
import CheckoutSubtitle from './CheckoutSubtitle'
import cardStyle from './cardStyle'
import { isValidDeliveryLocation } from '@cnd/common/functions/payment'
import CheckoutHeader from './CheckoutHeader'
import { FaArrowDownLong } from 'react-icons/fa6'
import Cutlery from '@components/CheckoutSteps/PlaceOrderForm/Cutlery'
import { absoluteFillBottomZ } from '@services/StyleSheet'
import ColoredButton from '@components/ColoredButton'

const Basket = ({ goToCheckout: next, back }) => {
  const { t } = useTranslation()
  const orders = useUser('orders')
  const [loadingNextPage, setLoadingNextPage] = useState(false)
  const items = useOrder('items')
  const isCollection = useOrder('isCollection')
  const deliveryLocation = useOrder('deliveryLocation')
  const dispatch = useTypedDispatch()
  const total = useOrder('total')
  const superTotal = useOrder('totalSuper')

  const removeItem = (key: string) => {
    dispatch(removeItemFromOrderByKey(key))
  }

  const goToCheckout = () => {
    setLoadingNextPage(true)

    const selectedMembership = memberships.hiro

    dispatch({ type: 'SET_USER', payload: { membership: { selected: true } } })
    if (!isMembershipInItems(items) && values(orders).length === 0) {
      dispatch(addItemToOrder(selectedMembership))
    }

    setLoadingNextPage(false)
    next()
  }

  let checkoutButtonDisabled = false
  if (!isCollection && !isValidDeliveryLocation(deliveryLocation)) checkoutButtonDisabled = true

  return (
    <>
      <CheckoutHeader
        title={t(`Your Order`)}
        right={
          <View style={{ padding: 10, width: 50 }} onClick={back} motion whileTap={{ scale: 0.8 }}>
            <FaArrowDownLong color={colors.nBlack()} size={14} />
          </View>
        }
      />
      <View style={{ display: 'block', height: '100%', overflow: 'scroll' }}>
        {isCollection ? <CollectFrom /> : <DeliverTo />}
        <Spacer height={40} />
        <View>
          <CheckoutSubtitle>Basket</CheckoutSubtitle>
          <View style={cardStyle}>
            {values(items).length > 0 && (
              <ScrollView absolute={false} height={'100%'} paddingBottom={0}>
                {map((item) => {
                  if (item.type === 'membership') return null
                  return <LineItem key={item.key} item={item} removeItem={removeItem} />
                }, values(items))}
              </ScrollView>
            )}
            {values(items).length === 0 && (
              <Text center style={{ padding: 10 }} weight={500}>
                Your basket is empty
              </Text>
            )}
          </View>
          <Cutlery />
        </View>
        <Spacer height={200} />
      </View>

      {values(items).length > 0 && (
        <View
          center
          style={{
            ...absoluteFillBottomZ(1),
            background: 'white',
            paddingTop: 10,
            borderTop: `1px solid rgba(0,0,0,0.08)`,
          }}
        >
          <View style={{ width: '100%', maxWidth: 550 }}>
            <View row spaceBetween style={{ paddingInline: 25 }}>
              <View row center>
                <Text size={14} center style={{ marginRight: 10 }}>
                  {t('Member savings')}
                </Text>
                <FaRegLaughWink size={18} />
              </View>
              <Text size={14}>£{priceToPounds(superTotal - total)}</Text>
            </View>

            <Spacer height={5} />

            <View row spaceBetween style={{ paddingInline: 25 }}>
              <Text size={14}>{t('Order total')}</Text>
              <View row style={{ justifyContent: 'center', alignItems: 'flex-end' }}>
                <Text size={12} style={{ textDecoration: 'line-through', marginRight: 8, marginBottom: 2 }}>
                  £{priceToPounds(superTotal)}
                </Text>{' '}
                <Text size={15} weight={500}>
                  £{priceToPounds(total)}
                </Text>
              </View>
            </View>

            <View
              center
              style={{
                paddingBottom: 25,
                paddingTop: 5,
                paddingInline: 20,
                opacity: loadingNextPage ? 0.3 : 1,
                width: '100%',
              }}
            >
              <ColoredButton
                disabled={checkoutButtonDisabled}
                color={colors.nBlack()}
                onClick={goToCheckout}
                size={55}
                full
                style={{ width: '100%' }}
                onDisabledClick={() => alert(`Please set delivery location.`)}
              >
                <Text color={colors.nWhite} weight={500} size={20}>
                  {t(`Checkout`)}
                </Text>
              </ColoredButton>
            </View>
          </View>
        </View>
      )}
    </>
  )
}
export default Basket
