import React, { useState } from 'react'

import { setDoubleSidebarBottom, setSidebarBottom } from '@store/actions'

import { SubHeading, View, Text, Dot, sectionStyle, LoadingAnimation } from '@cnd/elements'
import { Icon } from 'semantic-ui-react'
import useOrder from '@store/useOrder'

import Image from 'next/image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useTypedDispatch } from '@store'
import { isValidDeliveryLocation } from '@cnd/common/functions/payment'
import { useInterval } from 'usehooks-ts'
import api from '@services/api'
import { differenceInMinutes, format } from 'date-fns'
import CheckoutSubtitle from '@components/CheckoutSteps/CheckoutSubtitle'
import cardStyle from '@components/CheckoutSteps/cardStyle'

const DeliverTo = () => {
  const [isLoading, setIsLoading] = useState(false)
  const items = useOrder('items')
  const dispatch = useTypedDispatch()

  let isPreOrder = useOrder('isPreOrder')
  const expectedDeliveryTimeFrom = useOrder('expectedDeliveryTimeFrom')
  const expectedDeliveryTimeTo = useOrder('expectedDeliveryTimeTo')

  const setDeliverytimesOnOrder = async () => {
    if (!isPreOrder) {
      setIsLoading(true)
      const {
        data: { expectedDeliveryTimeFrom, expectedDeliveryTimeTo },
      } = await api.post(`/get_delivery_time`, { items })

      dispatch({ type: `UPDATE_ORDER`, payload: { expectedDeliveryTimeFrom, expectedDeliveryTimeTo } })
      setIsLoading(false)
    }
  }

  useInterval(() => {
    setDeliverytimesOnOrder()
  }, 10000)

  const deliveryLocation = useOrder('deliveryLocation')
  const { streetAddress1, streetAddress2, postcode } = deliveryLocation || {}

  const addDeliveryLocation = () => {
    dispatch(setDoubleSidebarBottom({ isOpen: true, content: 'AddDeliveryLocation' }))
  }

  if (deliveryLocation?.['atPartnerLocation']) return null

  return (
    <div style={{ cursor: 'pointer' }}>
      <CheckoutSubtitle>Deliver to</CheckoutSubtitle>
      <View style={cardStyle}>
        <View row spaceBetween center style={{ padding: 5 }} onClick={addDeliveryLocation}>
          {!isValidDeliveryLocation(deliveryLocation) && (
            <>
              <div>
                <Icon name={'map marker alternate'} color={'grey'} size={'small'} />
                <Text weight={500} size={15}>
                  Add delivery address
                </Text>
              </div>
              <FontAwesomeIcon icon={faArrowRight} fontSize={15} />
            </>
          )}
          {isValidDeliveryLocation(deliveryLocation) && (
            <div>
              <Icon name={'home'} size={'small'} />
              <Text size={15} weight={400} style={{ paddingLeft: 10, display: 'inline-block' }}>
                {streetAddress1 ? <>{streetAddress1},</> : null}{' '}
                {streetAddress2 ? <>{streetAddress2},</> : null} {postcode}
              </Text>
            </div>
          )}
        </View>
      </View>
      <View row style={{ padding: 10 }} spaceBetween>
        <View row style={{ alignItems: 'center' }}>
          <View>
            <Image
              src="/bike.webp"
              alt={'delivery icon'}
              width={26}
              height={26}
              style={{ marginRight: 10, marginLeft: 10, opacity: 0.9 }}
            />
          </View>
          <View>
            <Text>to your door</Text>
          </View>
        </View>
        <View row style={{ alignItems: 'center' }}>
          {!isLoading && (
            <>
              {isPreOrder && (
                <View style={{ marginInline: 10 }}>
                  <Text weight={400}>
                    {`on ${format(new Date(expectedDeliveryTimeFrom), 'eee')} 
                    ${format(new Date(expectedDeliveryTimeFrom), 'hh:mm')} -
                    ${format(new Date(expectedDeliveryTimeTo), 'hh:mm')}`}
                  </Text>
                </View>
              )}
              {!isPreOrder && (
                <View style={{ marginInline: 10 }}>
                  <Text weight={400}>
                    {`in ${differenceInMinutes(
                      new Date(expectedDeliveryTimeFrom),
                      new Date()
                    )} - ${differenceInMinutes(new Date(expectedDeliveryTimeTo), new Date())} mins`}
                  </Text>
                </View>
              )}
            </>
          )}
          {isLoading && (
            <View style={{ marginInline: 10 }}>
              <LoadingAnimation style={{ height: 12, width: 2 }} />
            </View>
          )}
        </View>
      </View>
    </div>
  )
}
export default DeliverTo
