import { AnimatePresence, Text, View } from '@cnd/elements'
import Lottie from 'lottie-react'
import groovyWalkAnimation from '../../public/placing_order.json'
import { useEffectOnce } from 'usehooks-ts'
import { useTypedDispatch, useTypedSelector } from '@store'
import api from '@services/api'
import { Device } from '@capacitor/device'
import { absoluteFillObject } from '@services/StyleSheet'
import RetroButton from '@components/RetroButton'
import { useRef } from 'react'

const PlacingOrder = ({ transactionId, userId }) => {
  const dispatch = useTypedDispatch()
  const order = useTypedSelector((s) => s.user.order)
  const phoneNumber = useTypedSelector((s) => s.user.phoneNumber)
  const email = useTypedSelector((s) => s.user.email)
  const placingError = useTypedSelector((s) => s.user.order?.placingError)
  const animation = useRef(null)

  const placeOrder = async () => {
    animation.current?.play()
    Device.getInfo().then(({ platform }) => {
      dispatch({
        type: 'CONFIRM_ORDER',
        payload: new Promise(async (resolve, reject) => {
          try {
            const { data: confirmedOrder } = await api.post(`/orders/confirm`, {
              order: {
                ...order,
                transactionId,
                platform,
                userId,
                customerPhoneNumber: phoneNumber,
                customerEmailAddress: email,
                state: 'PAID',
              },
            })
            resolve(confirmedOrder)
          } catch (error) {
            animation.current?.stop()
            reject(error)
          }
        }),
      })
    })
  }

  useEffectOnce(() => {
    placeOrder()
  })

  const retry = () => {
    placeOrder()
  }

  return (
    <>
      {placingError && (
        <View style={{ ...absoluteFillObject, background: `rgba(0,0,0,0.5)`, zIndex: 1 }} center>
          <AnimatePresence>
            <View
              motion
              key={`order-failed-to-place`}
              animate={{
                scale: [1, 0.95, 1],
                rotate: [1, -1, 1],
                transition: {
                  duration: 3,
                  repeatDelay: 2,
                  repeat: Infinity,
                },
              }}
              initial={{
                scale: 1,
              }}
              exit={{ scale: 0 }}
              style={{
                background: 'white',
                padding: 25,
                borderRadius: 3,
                minWidth: 100,
                width: '100%',
                maxWidth: 250,
              }}
              center
            >
              <View style={{ paddingBottom: 25, paddingTop: 10 }} center>
                <Text size={18} weight={700}>
                  Oh no!
                </Text>
                <Text center size={16}>
                  We failed to send your order to the kitchen
                </Text>
              </View>

              <RetroButton onClick={retry}>
                <Text size={15} color="black" weight={600}>
                  Tap to Retry
                </Text>
              </RetroButton>
            </View>
          </AnimatePresence>
        </View>
      )}
      <View>
        <View center style={{ marginTop: 35, marginBottom: 30 }}>
          <Text weight={600} size={22}>
            Placing order
          </Text>
        </View>
        <View center>
          <View center style={{ maxWidth: 350 }}>
            <Lottie lottieRef={animation} animationData={groovyWalkAnimation} />
          </View>
        </View>
      </View>
      <View center style={{ marginBottom: 65, marginTop: 45, width: '100%' }}>
        <Text center size={16} style={{ maxWidth: 290 }}>
          We're confirming your payment and sending your order to the kitchen.
        </Text>
      </View>
    </>
  )
}

export default PlacingOrder
