import { Text, View } from '@cnd/elements'

const CheckoutSubtitle = ({ children }) => {
  return (
    <View style={{ paddingLeft: 10 }}>
      <Text size={18} weight={500}>
        {children}
      </Text>
    </View>
  )
}
export default CheckoutSubtitle
