import { SidebarBottom } from './initialState'

export const clearState = () => ({ type: 'CLEAR_STATE' }) as const

export const closeSidebarBottom = () =>
  ({
    type: 'RESET_BOTTOMBAR',
  }) as const

export const setSidebarBottom = (payload: Partial<SidebarBottom>) =>
  ({
    type: 'REPLACE_BOTTOMBAR',
    payload,
  }) as const

export const setSidebarBottomIsOpen = (isOpen: boolean) =>
  ({
    type: 'REPLACE_BOTTOMBAR',
    payload: { isOpen, content: null } as Partial<SidebarBottom>,
  }) as const

export const closeDoubleSidebarBottom = () =>
  ({
    type: 'RESET_DOUBLE_BOTTOMBAR',
  }) as const

export const setDoubleSidebarBottom = (payload: Partial<SidebarBottom>) =>
  ({
    type: 'REPLACE_DOUBLE_BOTTOMBAR',
    payload,
  }) as const

export const setDoubleSidebarBottomIsOpen = (isOpen: boolean) =>
  ({
    type: 'REPLACE_DOUBLE_BOTTOMBAR',
    payload: { isOpen, content: null } as Partial<SidebarBottom>,
  }) as const

export const toggleSidebarMenu = () =>
  ({
    type: 'TOGGLE_SIDEBAR',
  }) as const

export const setSidebarMenu = (state) =>
  ({
    type: 'SET_SIDEBAR',
    payload: state,
  }) as const

export const setOnLoginReturnTo = (url) =>
  ({
    type: 'SET_ON_LOGIN_RETURN_TO',
    payload: url,
  }) as const
