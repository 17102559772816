import { View } from '@cnd/elements'

import Lottie from 'react-lottie'
import check from '../../../public/check.json'
import { useEffect, useState } from 'react'

const AnimatedTick = ({ animate = false }) => {
  const [isAnimationStopped, setIsAnimationStopped] = useState(true)
  const play = () => {
    setIsAnimationStopped(false)
  }

  const stop = () => {
    setIsAnimationStopped(true)
  }

  useEffect(() => {
    if (animate) play()
    if (!animate) stop()
  }, [animate])

  return (
    <View id="preparing" style={{ position: 'relative', width: 60, height: 60 }}>
      <View
        style={{
          background: 'rgba(247, 247, 247, 1)',
          borderRadius: 100,
          width: 24,
          height: 24,
          position: 'absolute',
          top: 18,
          right: 18,
        }}
      />
      <View style={{ position: 'absolute', left: -30, top: -30 }}>
        <Lottie
          width={120}
          hieght={120}
          options={{
            loop: false,
            autoplay: false,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
            animationData: check,
          }}
          isStopped={isAnimationStopped}
        />
      </View>
    </View>
  )
}

export default AnimatedTick
