import { memo } from 'react'
import LoginModalAddNumber from './LoginModalAddNumber'
import LoginModalConfirm from './LoginModalConfirm'

import { useTypedSelector } from '@store'

const LoginModal = () => {
  const authStatus = useTypedSelector((state) => state.auth.authStatus)

  if (authStatus === 'requesting-number') return <LoginModalAddNumber />
  if (authStatus === 'requesting-code') return <LoginModalConfirm />
  return null
}

export default memo(LoginModal)
