import { LoadingAnimation, Switch, Text, View } from '@cnd/elements'
import ColoredCard from '@components/ColoredCard'

const DietaryRequirementSwitch = ({ label, value, toggle, warning = null, pending = false }) => {
  return (
    <View style={{ width: '100%', maxWidth: 400 }}>
      <ColoredCard row spaceBetween style={{ width: '100%' }} alignCenter>
        <Text weight={500} size={16}>
          {label}
        </Text>
        {!pending && <Switch enabled={value} toggle={toggle} trackColor={`rgba(0,0,0,0.1)`} />}
        {pending && (
          <View center pad={10}>
            <LoadingAnimation style={{ height: 12, width: 3 }} />
          </View>
        )}
      </ColoredCard>

      {!!warning && (
        <View padL={20}>
          <Text>
            Warning, switching this switch will mean you will not see any menu items that are not in your
            allowed diet
          </Text>
        </View>
      )}
    </View>
  )
}

export default DietaryRequirementSwitch
