import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'
import { CapacitorStripeProvider } from '../../../node_modules/@capacitor-community/stripe/dist/esm/react/provider'
import { LoadingAnimation } from '@cnd/elements'

const LoadStripeApp = ({ children }) => {
  const [stripe, setStripe] = useState<any>(false)

  useEffect(() => {
    loadStripe(process.env.STRIPE_PUBLIC_APIKEY).then((stripe) => {
      setStripe(stripe)
    })
  }, [])

  if (!stripe) return <LoadingAnimation />

  return (
    <CapacitorStripeProvider
      publishableKey={process.env.STRIPE_PUBLIC_APIKEY}
      fallback={<LoadingAnimation />}
    >
      <Elements stripe={stripe}>{children}</Elements>
    </CapacitorStripeProvider>
  )
}

export default LoadStripeApp
