import { Text, View } from '@cnd/elements'

const CheckoutHeader = ({ title, left = null, right = null }) => {
  return (
    <View row spaceBetween center style={{ padding: 5 }}>
      {!left && <View style={{ width: 45 }} />}
      {!!left && <View style={{ width: 45 }}>{left}</View>}

      <Text
        center
        weight={400}
        size={15}
        style={{
          paddingTop: 32,
          paddingBottom: 28,
        }}
      >
        {title}
      </Text>
      {!right && <View style={{ width: 45 }} />}
      {!!right && <View style={{ width: 45 }}>{right}</View>}
    </View>
  )
}
export default CheckoutHeader
