import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js'
import { PaymentRequest } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import api from '@services/api'
import { useTypedDispatch } from '@store'

import { LoadingAnimation, View } from '@cnd/elements'
import { createAndSendError } from '@services/create-sentry-error'
import { STRIPE_ENV } from '@constants/stripe'

import useOrder, { usePreparedOrder } from '@store/useOrder'
import { isApp } from '@services/platform'
import { split } from 'ramda'

let useGooglePay
if (isApp) {
  useGooglePay = require('@store/useGooglePayApp').default
} else {
  useGooglePay = require('@store/useGooglePayWeb').default
}

const createIdempotencyKey = (order, paymentMethodId) => {
  const [a, b, c] = split('-', order.id)
  return `${a}-${b}-${c}_${order.humanId}_${order.totalIncludingDelivery}_${order.itemsCount}_${paymentMethodId}`
}

const payForOrderWithGooglePay = async (stripe, googleWallet, order) => {
  const newOrderTransaction = {
    businessId: !!order.businessId ? order.businessId : `2e04c7bb-d0b2-4275-9b93-7410d76e92bf`,
    idempotencyKey: createIdempotencyKey(order, `google-wallet`),
    isFor: 'ORDER',
    orderId: order.id,
    amount: order.totalIncludingDelivery,
    currency: 'gbp',
    customerSourceId: `google-wallet`,
    humanId: order.humanId,
    env: STRIPE_ENV,
    userId: order.userId,
    stripeCustomerId: order.stripeCustomerId,
    customerPhoneNumber: order.customerPhoneNumber,
  }
  let { data: transaction } = await api.post('/transactions', newOrderTransaction)

  console.log({ transaction })

  if (transaction.state === 'PAID') {
    googleWallet.complete('success')
    return transaction
  }

  let confirmedTransaction
  if (transaction.state === 'AWAITING_CONFIRMATION') {
    const clientSecret = transaction.paymentIntentClientSecret
    const stripeConfirmResult = await stripe.confirmCardPayment(
      clientSecret,
      { payment_method: googleWallet.paymentMethod.id },
      { handleActions: false }
    )

    if (stripeConfirmResult?.error) {
      throw createAndSendError(`There was a problem confiming your payment with Google. Please try again.`)
    }

    if (stripeConfirmResult.paymentIntent.status === 'requires_action')
      await stripe.confirmCardPayment(clientSecret)

    const { data: newTransaction } = await api.post('/transactions', newOrderTransaction)
    confirmedTransaction = newTransaction
  }

  googleWallet.complete('success')

  return confirmedTransaction
}

const PayWithGooglePayOnWeb = () => {
  const stripe = useStripe()
  const dispatch = useTypedDispatch()

  const totalIncludingDelivery = useOrder('totalIncludingDelivery')
  const paymentPending = useOrder('paymentPending')
  const order = usePreparedOrder()

  const { createPaymentRequestOptions } = useGooglePay({
    paymentTotal: totalIncludingDelivery,
  })

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>()

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest(createPaymentRequestOptions({ total: totalIncludingDelivery }))
      pr.on('paymentmethod', (googleWallet) => {
        dispatch({
          type: 'PAY_FOR_ORDER',
          payload: payForOrderWithGooglePay(stripe, googleWallet, order),
        })
      })
      pr.canMakePayment().then((result) => {
        if (result && result.googlePay) setPaymentRequest(pr)
      })
    }
  }, [
    order.totalIncludingDelivery,
    stripe,
    order.id,
    order.humanId,
    order.userId,
    order.stripeCustomerId,
    order.customerPhoneNumber,
    order.itemsCount,
  ])

  if (!paymentRequest || paymentPending)
    return (
      <View center>
        <LoadingAnimation />
      </View>
    )

  return (
    <View style={{ margin: 5 }}>
      <PaymentRequestButtonElement
        options={{
          paymentRequest,
          style: {
            paymentRequestButton: {
              type: 'default',
              theme: 'dark',
            },
          },
        }}
      />
    </View>
  )
}

export default PayWithGooglePayOnWeb
