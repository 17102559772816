import { useTypedDispatch } from '@store'
import { removeEmpty } from '@cnd/redash/dist/removeEmpty'
import { useCallback } from 'react'

import unset from 'lodash/unset'
import api from '@services/api'
import useUser from '@store/useUser'
import { User } from '@cnd/common/types'
import { createError } from '@cnd/redash'
import { dissoc } from 'ramda'

export const updateUser = (user: Partial<User> & { userId: string }) => {
  if (!user.userId) throw createError('Missing user Id')
  return api.post(`users/${user.userId}`, { user: dissoc('userId', user) })
}

const usePostUser = () => {
  const dispatch = useTypedDispatch()

  const userId = useUser('userId')
  const pending = useUser('pending')
  const accessToken = useUser('accessToken')

  const postUser = useCallback(
    (user: any) => {
      let localAccessToken = user.accessToken ? user.accessToken : accessToken
      let localUserId = user.userId ? user.userId : userId

      unset(user, user.accessToken)
      unset(user, user.userId)

      return {
        type: 'POST_USER',
        payload: api.post(`/updateUser`, { userId: localUserId, accessToken: localAccessToken, user }),
      } as const
    },
    [userId, accessToken]
  )

  const dispatchPostUser = (user: any) => dispatch(postUser(removeEmpty(user)))
  const dontDispatchPostUser = (user: any) => {}

  return pending ? dontDispatchPostUser : dispatchPostUser
}

export default usePostUser
