import { Nfc, NfcUtils, NfcTagTechType } from '@capawesome-team/capacitor-nfc'

export const createNdefTextRecord = () => {
  const utils = new NfcUtils()
  const { record } = utils.createNdefTextRecord({ text: 'Capacitor NFC Plugin' })
  return record
}

export const write = async () =>
  new Promise((resolve: any) => {
    const record = createNdefTextRecord()

    Nfc.addListener('nfcTagScanned', async (event) => {
      await Nfc.write({ message: { records: [record] } })
      await Nfc.stopScanSession()
      resolve()
    })

    Nfc.addListener('scanSessionCanceled', async () => {
      await Nfc.stopScanSession()
      resolve()
    })

    Nfc.addListener('scanSessionError', async () => {
      await Nfc.stopScanSession()
      resolve()
    })

    Nfc.startScanSession()
  })

export const read = async () =>
  new Promise((resolve, reject) => {
    Nfc.addListener('nfcTagScanned', async (event) => {
      await Nfc.stopScanSession()
      resolve(event)
    })

    Nfc.addListener('scanSessionCanceled', async () => {
      await Nfc.stopScanSession()
      reject()
    })

    Nfc.addListener('scanSessionError', async () => {
      await Nfc.stopScanSession()
      reject()
    })

    Nfc.startScanSession()
  })

export const makeReadOnly = async () =>
  new Promise((resolve: any) => {
    Nfc.addListener('nfcTagScanned', async (event) => {
      await Nfc.makeReadOnly()
      await Nfc.stopScanSession()
      resolve()
    })

    Nfc.startScanSession()
  })

export const readSignature = async () =>
  new Promise((resolve) => {
    Nfc.addListener('nfcTagScanned', async (event) => {
      const { response } = await Nfc.transceive({ techType: NfcTagTechType.NfcA, data: [60, 0] })
      await Nfc.stopScanSession()
      resolve(response)
    })

    Nfc.startScanSession()
  })

export const erase = async () =>
  new Promise((resolve: any) => {
    Nfc.addListener('nfcTagScanned', async (event) => {
      await Nfc.erase()
      await Nfc.stopScanSession()
      resolve()
    })

    Nfc.startScanSession()
  })

export const format = async () =>
  new Promise((resolve: any) => {
    Nfc.addListener('nfcTagScanned', async (event) => {
      await Nfc.format()
      await Nfc.stopScanSession()
      resolve()
    })

    Nfc.startScanSession()
  })

export const isSupported = async () => {
  const { isSupported } = await Nfc.isSupported()
  return isSupported
}

export const isEnabled = async () => {
  const { isEnabled } = await Nfc.isEnabled()
  return isEnabled
}

export const openSettings = async () => {
  await Nfc.openSettings()
}

export const checkPermissions = async () => {
  const { nfc } = await Nfc.checkPermissions()
  return nfc
}

export const requestPermissions = async () => {
  const { nfc } = await Nfc.requestPermissions()
  return nfc
}

export const removeAllListeners = async () => {
  await Nfc.removeAllListeners()
}

export const getDataFromNFC = async () => {
  await requestPermissions()

  const result: any = await read()

  const utils = new NfcUtils()
  const data = utils.convertBytesToString({ bytes: result.nfcTag.message.records[0].payload }).text

  return data
}
