import { isValidDeliveryLocation } from '@cnd/common/functions/payment'
import { useTypedSelector } from '@store'
import useOrder from '@store/useOrder'

const usePayment = () => {
  const totalIncludingDelivery = useOrder('totalIncludingDelivery')
  const deliveryLocation = useOrder('deliveryLocation')
  const isCollection = useOrder('isCollection')
  const isLoggedIn = useTypedSelector((state) => state.user.isLoggedIn)

  const canDeliverOrCollect = isCollection || isValidDeliveryLocation(deliveryLocation)
  const isTotalGreaterThanZero = totalIncludingDelivery > 0

  return {
    isReadyToTakePayment: isTotalGreaterThanZero && canDeliverOrCollect && isLoggedIn,
    canDeliverOrCollect,
    isLoggedIn,
  }
}

export default usePayment
