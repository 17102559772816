import { Warning, View, LoadingAnimation, Spacer, Loading, Text, HR } from '@cnd/elements'
import OrderTimes from './OrderTimes'
import BasketSummary from '@components/CheckoutSteps/PlaceOrderForm/BasketSummary'

import { isStatus } from '@cnd/common/functions/menu'
import { useTranslation } from 'react-i18next'
import { FaArrowDownLong } from 'react-icons/fa6'
import colors from '@constants/colors'

import cardStyle from '../cardStyle'
import hasPasedStatus from './hasPassedStatus'
import AnimatedTick from './AnimatedTick'
import { map, split } from 'ramda'
import { capitalize } from 'lodash'
import OrderHelp from '../OrderHelp'
import ColoredCard from '@components/ColoredCard'
import useRealtimeDocument from '@hooks/useRealtimeDocument'
import WonFreeOrder from './WonFreeOrder'
import { Order } from '@cnd/common/types'

const CheckoutOrder = ({ orderId, back }) => {
  const { t } = useTranslation()

  const order = useRealtimeDocument({ collection: `orders`, doc: orderId }, [orderId]) as Order

  if (!order) return <Loading />

  const isOrderStatus = isStatus(order)
  const isOutForDelivery = () => isOrderStatus('OUT_FOR_DELIVERY') && !!order?.deliveryLocation?.latLng

  const { state, humanId } = order

  return (
    <>
      {order.isFree && <WonFreeOrder />}
      <View center>
        <View style={{ width: '100%', maxWidth: 500, padding: 20 }} row spaceBetween>
          <View style={{ padding: 10, width: 50 }} onClick={back} motion whileTap={{ scale: 0.8 }}>
            <FaArrowDownLong color={colors.nBlack()} size={14} />
          </View>

          <OrderHelp orderId={orderId} />
        </View>

        {!order && (
          <View center style={{ marginTop: 20 }}>
            <LoadingAnimation />
          </View>
        )}

        <ColoredCard width={'90%'} style={{ minWidth: 310 }} margin={0} center>
          <OrderTimes isOutForDelivery={isOutForDelivery} order={order} />
          <View row style={{ position: 'relative' }}>
            <View row style={{ position: 'absolute', height: 2, top: 30, left: 20 }}>
              <View
                style={{
                  background: hasPasedStatus('PREPARING', state) ? '#7E1900' : 'rgb(204, 204, 204)',
                  width: 260 / 4,
                  height: 2,
                }}
              />
              <View
                style={{
                  background: hasPasedStatus('READY_FOR_COLLECTION', state)
                    ? '#7E1900'
                    : 'rgb(204, 204, 204)',
                  width: 260 / 4,
                  height: 2,
                }}
              />
              <View
                style={{
                  background: hasPasedStatus('OUT_FOR_DELIVERY', state) ? '#7E1900' : 'rgb(204, 204, 204)',
                  width: 260 / 4,
                  height: 2,
                }}
              />
              <View
                style={{
                  background: hasPasedStatus('DELIVERED', state) ? '#7E1900' : 'rgb(204, 204, 204)',
                  width: 260 / 4,
                  height: 2,
                }}
              />
            </View>

            <AnimatedTick animate={hasPasedStatus('CONFIRMED', state)} />
            <AnimatedTick animate={hasPasedStatus('PREPARING', state)} />
            <AnimatedTick animate={hasPasedStatus('READY_FOR_COLLECTION', state)} />
            <AnimatedTick animate={hasPasedStatus('OUT_FOR_DELIVERY', state)} />
            <AnimatedTick animate={hasPasedStatus('DELIVERED', state)} />
          </View>

          {order.state === 'CONFIRMED' && (
            <Text weight={400} style={{ marginBottom: 10 }}>
              We will prepare your order soon
            </Text>
          )}

          {order.state === 'PREPARING' && (
            <Text weight={400} style={{ marginBottom: 10 }}>
              We're preparing your order
            </Text>
          )}
          {order.state === 'READY_FOR_COLLECTION' && (
            <Text weight={400} style={{ marginBottom: 10 }}>
              Your order is ready!
            </Text>
          )}
          {order.state === 'OUT_FOR_DELIVERY' && (
            <Text weight={400} style={{ marginBottom: 10 }}>
              Your order is on it's way to you!
            </Text>
          )}
          {order.state === 'DELIVERED' && (
            <Text weight={400} style={{ marginBottom: 10 }}>
              We've delivered your order
            </Text>
          )}
          <Spacer height={10} />

          {order.state !== 'DELIVERED' && (
            <>
              <HR width="90%" />
              <Spacer height={15} />
              <View style={{ width: '100%', padding: 5 }} center>
                <Text size={14} weight={500}>
                  Give your waiter this code
                </Text>
                <Text size={14} weight={400}>
                  After you've recieved your order
                </Text>
                <Spacer height={5} />

                <View row>
                  {map(
                    (h) => (
                      <Text
                        center
                        style={{
                          paddingInline: 15,
                          paddingBlock: 10,
                          backgroundColor: 'rgba(0,0,0,0.06)',
                          borderRadius: 15,

                          margin: 7,
                        }}
                        key={h}
                        weight={600}
                        size={18}
                      >
                        {capitalize(h)}
                      </Text>
                    ),
                    split('-', humanId)
                  )}
                </View>
              </View>
            </>
          )}
        </ColoredCard>

        {order && (
          <View center style={{ ...cardStyle, padding: 20, width: '90%', minWidth: 310, maxWidth: 550 }}>
            <BasketSummary order={order} />
            <Spacer height={60} />
            {isOrderStatus('cancelled') && (
              <div style={{ width: '100%', textAlign: 'center', padding: '20px 20px 50px 20px' }}>
                <Warning>
                  {t('This order has been cancelled, you should get your refund in 2-5 working days.')}
                </Warning>
              </div>
            )}
          </View>
        )}
        <View style={{ padding: 20 }}>
          <Text color="rgba(0,0,0,0.8)" size={10}>
            <Text color="rgba(0,0,0,0.6)" size={10} block={false}>
              ID
            </Text>{' '}
            {orderId}
          </Text>
        </View>
      </View>
    </>
  )
}

export default CheckoutOrder
